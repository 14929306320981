import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ProductService } from "../../../shared/services/product.service";
import { Product } from '../../../shared/classes/product';
import { ParamMap, NavigationEnd } from '@angular/router';
import * as _ from 'lodash'
import { CategoriesService } from 'src/app/shared/services/categories';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: "app-collection-trademarkes",
  templateUrl: "./collection-trademarkes.component.html",
  styleUrls: ["./collection-trademarkes.component.scss"],
})
export class CollectionTrademarkesComponent {
  public grid: string = "product-col-container1";
  public layoutView: string = "grid-view";
  public all_products: any[] = [];
  public products: any[] = [];
  public allItems: Product[] = [];
  public brands: any[] = [];
  public brand_name: any = "";
  public brand_id: any = "";
  public colors: any[] = [];
  public size: any[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 5000;
  public tags: any[] = [];
  public category: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;
  public finished: boolean = false; // boolean when end of data is reached
  public addItemCount = 8;
  public start = 0;
  public allShownProducts: any[] = [];

  public allDatafilter: any[] = [];
  public allCategoryfilter: any[] = [];
  public all_category_ids: any = {};
  formsIdParams: any = {};
  formsShapes: any[] = [];
  formShapes4: any = {};
  filterBody: any;

  disabledOption: boolean = false;

  // CATEGORY FILTER
  public categories: any[] = [];
  public filterCategories: any[] = [];
  filterCategoriesParams: any[] = [];
  public categoryFilter: any[] = [];
  public categoryFilterTags: any[] = [];

  // Status FILTER
  public filterStatus: any[] = [];
  filterStatusParams: any[] = [];
  public statusFilter: any[] = [];
  public statusFilterTags: any[] = [];

  // CUSTOM PRICES MULTI SELECT
  filterPricesParams: any[] = [];
  searchMultiPrices: any[] = [];
  pricesFilter: any;
  pricesBody: any[] = [];
  pricesFilterTags: any[] = [];

  filterShapesParams: any[] = [];
  formShapesFilterTags: any[] = [];
  fetchedIds: Set<any> = new Set();

  loading: boolean = true;
  loadingImgUrl: string = "";
  public getNoData: boolean = false;

  allDataFilters: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,
    public productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoriesService,
    public Common: CommonService,
  ) {

    // RESET SHOWN PRODUCTS WHEN NAVIGATION CHANGES
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.allShownProducts = [];
        this.start = 0;
      }
    });

    this.route.queryParams.subscribe((params) => {

      this.products = [];
      this.finished = false;

      /* -------------------------------------------------------------------------- */
      /*                START GET PARAMETERS OF FORM SHAPES FROM URL                */
      /* -------------------------------------------------------------------------- */
      const queryString = window.location.search;
      const subQueryString = queryString.substring(1);

      let formShapes1 = [];
      let formShapes2 = [];
      let formShapes3 = [];
      let formShapes4 = {};

      /* -------------------------------------------------------------------------- */
      /*                             CATEGORY FILTER                            */
      /* -------------------------------------------------------------------------- */
      // this.prices = params.prices?.length > 0 ? params.prices.split(",") : [];

      this.categoryFilter =
        params.catFilter?.length > 0 ? params.catFilter.split(",") : [];

      this.statusFilter =
        params.filter_type?.length > 0 ? params.filter_type.split(",") : [];

      /* ------------------------- END OF CATEGORY FILTER ------------------------- */

      formShapes1 = subQueryString ? subQueryString.split("&") : [];
      for (let index = 0; index < formShapes1.length; index++) {
        if (formShapes1[index].slice(0, 5) == "form_") {
          formShapes2.push(formShapes1[index]);
        }
      }

      for (let index = 0; index < formShapes2.length; index++) {
        formShapes3.push(
          formShapes2[index].split(/, (?=[^,]+:)/).map((s) => s.split("="))
        );
      }

      for (let index = 0; index < formShapes3.length; index++) {
        formShapes4[formShapes3[index][0][0]] = formShapes3[index][0][1]
          .split(/, (?=[^,]+:)/)
          .map((s) => s.split(","))[0];
      }

      this.formShapes4 = formShapes4;

      // shape4 => form_shapeID => [option ids]
      /* ---------------- MAKING THE BODY OF API FOR FILTERED DATA ---------------- */

      this.filterBody = {};
      let arr = [];

      // console.log("formShapes4", formShapes4, formShapes2, formShapes3);

      for (let index = 0; index < formShapes2.length; index++) {
        let formKey = formShapes3[index][0][0].slice(5);
        let formValue = formShapes3[index][0][1];
        let obj = {};

        obj["key"] = formKey;
        obj["value"] = formValue;

        arr.push(obj);
      }

      this.filterBody["form_shape"] = arr;

      /* --------------- END OF GETTING FORM SHAPES PARAMS FROM URL --------------- */

      this.brands = params.brand ? params.brand.split(",") : [];
      this.colors = params.color ? params.color.split(",") : [];
      this.size = params.size ? params.size.split(",") : [];

      this.tags = [...this.brands, ...this.colors, ...this.size]; // All Tags Array

      this.category = params.category ? params.category : null;
      this.sortBy = params.sortBy ? params.sortBy : "";

      this.getCategoryData();

      /* -------------------------------------------------------------------------- */
      /*                           Get Filtered Products..                          */
      /* -------------------------------------------------------------------------- */
      this.productService.filterProducts(this.tags).subscribe((response) => {
        // console.log("filtered products", response);
        // All Products
        this.all_products = response;

        // Sorting Filter
        this.all_products = this.productService.sortProducts(
          response,
          this.sortBy
        );

        // Category Filter
        if (params.category) {
          this.all_products = this.all_products?.filter(
            (item) => item.type == this.category
          );
        }

        // Price Filter
        this.all_products = this.all_products?.filter(
          (item) => item.price >= this.minPrice && item.price <= this.maxPrice
        );

        this.all_products = this.all_products?.filter(() => { });

        this.addItems();
      });
      /* --------------------- END OF Get Filtered Products.. --------------------- */

      /* -------------------------------------------------------------------------- */
      /*                   MAKING THE BODY FOR PRICES MULTISELECT                   */
      /* -------------------------------------------------------------------------- */

      this.pricesFilter = params.prices?.length > 0 ? params.prices.split(",") : [];

      this.pricesBody["searchMultiPrices"] = [];
      let testArr2 = [];

      for (let index = 0; index < this.pricesFilter.length; index++) {
        var splitted = this.pricesFilter[index].split("-");
        let priceElementObj = { from: splitted[0], to: splitted[1] };
        testArr2.push(priceElementObj);
      }

      this.filterBody["searchMultiPrices"] = testArr2;
      this.pricesFilterTags = this.filterBody["searchMultiPrices"];

      this.statusFilterTags = params.filter_type?.length > 0 ? params.filter_type.split(",") : [];
      /* -------------- END OF MAKING THE BODY FOR PRICES MULTISELECT ------------- */

    });
  }

  /* -------------------------------------------------------------------------- */
  /*                      CATEGORY FILTER GET FILTER NAMES                      */
  /* -------------------------------------------------------------------------- */

  // CHANGE CATEGORY FILTER CHECKBOX

  /* ----------------- END OF status FILTER GET FILTER NAMES ---------------- */

  getCategoryData() {
    this.loading = true;
    this.finished = false;
    this.route.params.subscribe((params: ParamMap) => {
      this.loading = true;
      this.finished = false;
      this.brand_id = params["slug"];
      this.brand_name = params["name"].split('-').join(' ');

      /* -------------------------------------------------------------------------- */
      /*            MODIFY sortBy VARIABLE TO BE ADJUSTED WITH API PARAM            */
      /* -------------------------------------------------------------------------- */
      let type = '';
      let sort = '';
      switch (this.sortBy) {
        case 'a-z':
          type = 'ASC';
          sort = 'name';
          break;

        case 'z-a':
          type = 'DESC';
          sort = 'name';
          break;
        case 'low':
          type = 'ASC';
          sort = 'price';
          break;
        case 'high':
          type = 'DESC';
          sort = 'price';
          break;
        default:
          break;
      }

      // GET BRAND DESCRIPTION
      this.getTrademarkInfo(this.brand_id)
      // SET TITLE
      // this.Common.setTitle(this.brand_name);
      this.Common.setTitle(this.brand_name , true);

      this.loading = true;
      this.finished = false;
      this.getNoData = false;

      this.productService.getProductsPayParams(
        this.categoryFilter, "", this.brand_id, "", this.filterBody, this.start, 50, sort, type, this.statusFilter
      )
        .subscribe((products) => {

          // GET CATEGORY FILTER
          
          if (products.data) {
            
            if (this.start == 0) {
              this.allItems = [];
              this.allShownProducts = [];
              this.fetchedIds.clear();
              this.getAllMainCategories();
            }
            
            products.data.forEach(product => {
              if (!this.fetchedIds.has(product.id)) { // Check if ID is not already fetched
                this.fetchedIds.add(product.id); // Add ID to the Set
                this.allItems.push(product); // Add product to the data array
              }
            });
            this.all_products = this.allItems;
            this.allShownProducts = this.allItems;
            
            // console.log(this.fetchedIds);
            // console.log(this.allShownProducts);
            // console.log("this.products second",this.allItems);
            this.loading = false;
            this.finished = true;
            // console.log("products in first method",this.allItems);
            if (this.allItems.length == 0) {
              //console.log("no data in products");
              this.getNoData = true;
            }
            //console.log"SCROLLED PRODUCTS IN LIST FROM START", this.allItems);
            // Same array push

            //Array.prototype.push.apply(this.allShownProducts, this.allItems);

            // console.log("SCROLLED PRODUCTS SHOW", this.allShownProducts);
            // this.products = products.slice(0.8);
          } else {
            //console.log"no data in products");
            this.getNoData = true;
            this.loading = false;
            this.allItems = [];
            this.finished = true;
          }
        });
    });
  }

  getAllMainCategories() {

    this.categoryService.getMainCategories().subscribe((data) => {
      this.categories = data.data;
      // console.log(data);
      
      var categoriesArray = this.all_category_ids?.length > 0 ? this.all_category_ids.split(",") : [];
      let catResult = this.categories.filter((o1) =>
        categoriesArray.some((o2) => o1.id === o2)
      );
      this.filterCategories = catResult;
      this.categoryFilterTags = this.filterCategories.filter((o1) =>
        this.categoryFilter.some((o2) => o1.id === o2)
      );

      this.getFiltersOfProducts(); // get filters of products and get selected filter 
    });

  }

  getFiltersOfProducts() {
    // if (this.start == 0) {
    //   this.allShownProducts = [];
    // }
    this.productService.getFiltersOfProducts('', this.brand_id).subscribe((res) => {
      this.allDatafilter = res['data'].filter((ele) => ele.mode !== 'trademark' && ele.options?.length > 0);
      this.allCategoryfilter = res['data'].filter((ele) => ele.mode == 'category' && ele.options?.length > 0);

      this.all_category_ids = [];
      this.allCategoryfilter.forEach(ele => {
        ele.options.forEach(option => {
          this.all_category_ids.push(option.value);
        });
      });
      this.all_category_ids = this.all_category_ids.join(',')
      this.productService.getFormShapes(this.all_category_ids).subscribe((res) => {
        res.data.forEach(ele => {
          this.allDatafilter.push(ele);
        });

        let arr1 = [];
        for (const key in this.formShapes4) {
          const value = this.formShapes4[key];

          let string = key.substring(5);

          let object1 = { id: string, options: value };
          arr1.push(object1);
        }

        let result = this.allDatafilter.filter((o1) => {
          return arr1.some((o2) => o1.id === o2.id)
        });

        let result_1 = result;
        let arr1_1 = arr1.map((obj) => obj.options);
        var labels = [];

        this.formShapesFilterTags = [];
        for (var i = 0; i < result_1.length; ++i) {

          let options1 = result_1[i]["options"];
          for (let k = 0; k < options1.length; k++) {
            for (var j = 0; j < arr1_1.length; ++j) {
              for (let m = 0; m < arr1_1[j].length; m++) {

                let x = options1[k]["value"];
                let y = arr1_1[j][m];
                if (x == y) {

                  let formIdTest = result_1[i]["id"];
                  options1[k]["formId"] = formIdTest;
                  this.formShapesFilterTags.push(options1[k]); // Push to common array
                }
              }
            }
          }
        }

        for (let index = 0; index < this.allDatafilter.length; index++) {
          let formKey = "form_" + this.allDatafilter[index]["id"];
          if ((this.formsIdParams[formKey] = this.formShapes4[formKey])) {
            this.formsIdParams[formKey] = this.formShapes4[formKey];
          } else {
            this.formsIdParams[formKey] = [];
          }
        }
      });
    });
  }

  getTrademarkInfo(brandId: any) {
    this.Common.getAllBrands('', brandId).subscribe((data) => {
      if (data.success) {
        const brandDescription = data.data[0].description;
        //console.log"Brand Description ", brandDescription);
        this.Common.setMeta("description", brandDescription);
        this.Common.setMeta("", brandDescription, true, 'og:description');
      }
    })
  }

  // CHECK IF CATEGORY EXIST IN URL CHECKBOX
  categoryExists(catId): boolean {
    return this.categoryFilter.includes(catId) ? true : false
  }

  // CHECK IF CATEGORY EXIST IN URL CHECKBOX
  avilableStatusExists(statusId): boolean {
    return this.statusFilter.includes(statusId) ? true : false;
  }

  // CHECK IF PRICES EXIST IN URL CHECKBOX
  pricesExists(from: any, to: any): boolean {
    return this.pricesFilter.includes(`${from}-${to}`) ? true : false;
  }

  // CHECK IF FORM EXISTS IN URL PARAMS
  formShapeExists(formShapeId: any, optionShapeValue: any, mode: string): boolean {
    if (mode == 'category') {
      return this.categoryExists(optionShapeValue);
    }
    if (mode == 'price') {
      let from = optionShapeValue[0];
      let to = optionShapeValue[1];
      return this.pricesExists(from, to);
    }
    if (mode == 'availability_status') {
      return this.avilableStatusExists(optionShapeValue);
    }
    if (formShapeId !== null) {
      let string = "form_" + formShapeId;

      if (this.formsIdParams[string]) {
        let x = this.formsIdParams[string].indexOf(optionShapeValue) > -1;
        return x;
      } else {
        return false;
      }
    }
  }

  checkCategoryFilter(event, id) {
    if (event.currentTarget.checked) {
      this.filterCategoriesParams.push(id);
    } else {
      this.filterCategoriesParams.forEach((element, index) => {
        if (element == id) this.filterCategoriesParams.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.filterCategoriesParams.toString();

    obj2 = { catFilter: string1 };

    if (obj2["catFilter"] == "") {
      obj2["catFilter"] = [];
    }
    this.updateFilter(obj2);
  }

  checkAvilableStatusFilter(event, id) {
    if (event.currentTarget.checked) {
      this.filterStatusParams.push(id);
    } else {
      this.filterStatusParams.forEach((element, index) => {
        if (element == id) this.filterStatusParams.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.filterStatusParams.toString();

    obj2 = { filter_type: string1 };

    if (obj2["filter_type"] == "") {
      obj2["filter_type"] = [];
    }
    this.updateFilter(obj2);
  }

  checkValuePrice(event: any, min: any, max: any) {
    let singlePrice = min + "-" + max;

    if (event.currentTarget.checked) {
      this.filterPricesParams.push(singlePrice);
    } else {
      this.filterPricesParams.forEach((element, index) => {
        if (element == singlePrice) this.filterPricesParams.splice(index, 1);
      });
    }

    let obj2 = {};
    let string1 = this.filterPricesParams.toString();
    obj2 = { prices: string1 };

    if (obj2["prices"] == "") {
      obj2["prices"] = [];
    }
    this.updateFilter(obj2);
  }

  checkValueForm(event: any, formShapeId: any, optionShapeId: any, mode: string, label: string = '') {
    if (mode == 'category') {
      this.checkCategoryFilter(event, optionShapeId);
    }

    if (mode == 'availability_status') {
      this.checkAvilableStatusFilter(event, optionShapeId);
    }

    if (mode == 'price') {
      let min = optionShapeId.split(',')[0];
      let max = optionShapeId.split(',')[1];
      this.checkValuePrice(event, min, max)
    }

    if (formShapeId !== null) {
      // console.log("this.formShapes4 ", this.formShapes4, formShapeId, this.formsIdParams);
      let formId = "form_" + formShapeId;
      this.formsIdParams[formId] = (this.formsIdParams[formId] instanceof Array) ? this.formsIdParams[formId] : [this.formsIdParams[formId]];
      if (event.currentTarget.checked) {
        this.disabledOption = true;
        this.formsIdParams[formId].push(optionShapeId);
        this.formShapesFilterTags.push({ value: optionShapeId, label: label, formId: formId })
      }
      else {
        this.disabledOption = true;
        this.formsIdParams[formId].forEach((element, index) => {
          if (element == optionShapeId) {
            this.formsIdParams[formId].splice(index, 1);
          }
        });

        this.formShapesFilterTags.forEach((element, index) => {
          if (element.value == optionShapeId) {
            this.formShapesFilterTags[index] = (this.formShapesFilterTags[index] instanceof Array) ? this.formShapesFilterTags[index] : [];
            this.formShapesFilterTags[index].splice(index, 1);
          }
        });

      }

      for (const key in this.formsIdParams) {
        const value = this.formsIdParams[key];
        if (typeof value === "string" || value instanceof String) {
          break;
        }
        if (Array.isArray(value)) {
          let string = this.formsIdParams[key].toString();
          this.formsIdParams[key] = string;
          if (this.formsIdParams[key] == "") {
            this.formsIdParams[key] = [];
          }
        }
      }

      this.disabledOption = false;

      this.updateFilter(this.formsIdParams);
    }
  }

  removeCategoryTag(id) {
    this.categoryFilter.forEach((element, index) => {
      if (element == id) this.categoryFilter.splice(index, 1);
    });

    let obj2 = {};
    let string1 = this.categoryFilter.toString();
    obj2 = { catFilter: string1 };

    // DELETE URL PRICE PARAM IF NO PRICE RANGE SELECTED
    if (obj2["catFilter"] == "") {
      obj2["catFilter"] = [];
    }

    this.updateFilter(obj2);
  }

  removeStatusTag(id) {
    this.statusFilter.forEach((element, index) => {
      if (element == id) this.statusFilter.splice(index, 1);
    });

    let obj2 = {};
    let string1 = this.statusFilter.toString();
    obj2 = { filter_type: string1 };

    if (obj2["filter_type"] == "") {
      obj2["filter_type"] = [];
    }

    this.updateFilter(obj2);
  }

  removeFormTag(value: any, formId1: any) {
    let formId = "form_" + formId1;
    this.formsIdParams[formId] = (this.formsIdParams[formId] instanceof Array) ? this.formsIdParams[formId] : [this.formsIdParams[formId]];
    this.formsIdParams[formId].forEach((element, index) => {
      if (element == value) this.formsIdParams[formId].splice(index, 1);
    });

    for (const key in this.formsIdParams) {
      const value = this.formsIdParams[key];

      if (typeof value === "string" || value instanceof String) {
        break;
      }
      if (Array.isArray(value)) {
        let string = this.formsIdParams[key].toString();
        this.formsIdParams[key] = string;

        if (this.formsIdParams[key] == "") {
          this.formsIdParams[key] = [];
          // delete this.formsIdParams[key];
        }
      }
    }
    this.updateFilter(this.formsIdParams);
  }

  // REMOVE PRICE TAG
  removePriceTag(from: any, to: any) {
    let singlePrice = from + "-" + to;

    this.pricesFilter.forEach((element, index) => {
      if (element == singlePrice) this.pricesFilter.splice(index, 1);
    });

    let obj2 = {};
    let string1 = this.pricesFilter.toString();
    obj2 = { prices: string1 };

    if (obj2["prices"] == "") {
      obj2["prices"] = [];
    }
    this.updateFilter(obj2);
  }

  addItems() {
    if (
      this.all_products?.length <= this.products?.length &&
      this.all_products?.length <= this.addItemCount
    ) {
      this.finished = true;
      return;
    }
    this.products = this.all_products?.slice(0, this.addItemCount);
  }

  // Infinite scroll
  public onScroll() {
    // add another items
    this.addItemCount += 8;
    this.addItems();

    this.start += 50;
    this.getCategoryData();
  }

  // Append filter value to Url
  updateFilter(tags: any) {
    this.allShownProducts = [];
    this.start = 0

    tags.page = null; // Reset Pagination
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: tags,
      queryParamsHandling: "merge", // preserve the existing query params in the route
      skipLocationChange: false, // do trigger navigation
    }).finally(() => {
      console.log("Finally completed")
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor("products"); // Anchore Link
    });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null },
      queryParamsHandling: "merge", // preserve the existing query params in the route
      skipLocationChange: false, // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor("products"); // Anchore Link
    });
  }

  // Remove Tag
  removeTag(tag) {
    console.log("Tag removed", tag);
    this.brands = this.brands.filter((val) => val !== tag);
    this.colors = this.colors.filter((val) => val !== tag);
    this.size = this.size.filter((val) => val !== tag);

    let params = {
      brand: this.brands.length ? this.brands.join(",") : null,
      color: this.colors.length ? this.colors.join(",") : null,
      size: this.size.length ? this.size.join(",") : null,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: "merge", // preserve the existing query params in the route
      skipLocationChange: false, // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor("products"); // Anchore Link
    });
  }

  // Clear Tags
  removeAllTags() {
    this.start = 0;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      skipLocationChange: false, // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor("products"); // Anchore Link
    });
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    console.log("Toggle Mobile Sidebar");
    this.mobileSidebar = !this.mobileSidebar;
  }

}
