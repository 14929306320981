<!-- <div class="countdown-banner-layout">
    <div class="deal-text-block">
        <i class="fa fa-bolt icon"></i>
        <span class="text">
        {{title}}
        </span>
        <i class="fa fa-bolt icon"></i>
    </div>
    
    <div class="timer-block" *ngIf="timeLeftTillOfferEnds">
        <span class="timer-text">
            {{'ends in'| translate}}
        </span>
        <cd-timer class="clock" format="user" countdown="true" [startTime]="timeLeftTillOfferEnds">[days]:[hours]:[minutes]:[seconds] </cd-timer>
    </div>
</div> -->

<div class="countdown-banner-layout ">
    <div class="deal-text-block">
        <i class="fa fa-bolt icon"></i>
        <span class="text">
            {{ title }}
        </span>
        <i class="fa fa-bolt icon"></i>
    </div>

    <div class="timer-block">
        <span class="timer-text">
            {{ 'ends in' | translate }}
        </span>
        <span class="clock">
            {{ timeLeft.hours }}:{{ timeLeft.minutes }}:{{ timeLeft.seconds }}
        </span>
    </div>
</div>