import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Order } from '../../../shared/classes/order';
import { OrderService } from '../../../shared/services/order.service';
import { ProductService } from '../../../shared/services/product.service';
import { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, AfterViewInit {
  public orderDetails: Order = {};
  order_number = '';
  constructor(public productService: ProductService, private orderService: OrderService, private route: ActivatedRoute, private router: Router,) {
  }
  ngOnInit(): void {
    
    this.orderService.checkoutItems.subscribe(response => {
      if(response) {
        this.orderDetails = response        
      }

    });
    this.route.params.subscribe(
      (params: ParamMap) => {
        const order_id = params['id'];
        if (order_id != '') {
          this.order_number = order_id;
        }
      }
    );

  }

  ngAfterViewInit() {}

}
