import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FatoorahPaymentService {
  private baseURL = "/api/";
  // private apiKey =
  //   "ukTtBHxFf_JbMpvXEkNsZ6PMnN_5tlJU4tBpxMNfjc16V9q_CwsLHRQ1twX8eoExjPt-GblWxxZZtg9hgflhHI9sFATOr4vvkDA9qVhpHnKRXNyEVTb6QIXv-Jct1TXQUTMMNh8r1BPvjJCOTd9GvYMF_10b2JmvFGNjStsVWvcTShXg_tTV9TxSJ5Zezx9dtZzhXcegbzvWoq2PgSBy7BQAdlq-M82HO-wXXWomjxbY0dwf3Ow3G0SvR6vR6uCMBSxQFzFnTPWyNXEycY_2uHuMGYBrVjbRZ5Bng4yAPoMv80mxoMb-zFjIGKd__o_Kk88IbmbofGz7LKPUh81Mdnz6QvrvFnycnC-okG_Tfvd2OEFNplj_84DPCHhXZ_4MrBtd6VrMsnfUMIdAlruzkmEyeLTQTvpwbSXmSrr5TjxXSdI22b7lhAdRTkoiHdSH8dIt_uRcpOIFwP7sp0YwqX3MMAnzmXUtyuSmhUVl6KJy5njOa3Y1Wrk4zfjmd6sSjND_NackRBTNQp6jKgmH-VeGD5q20Wcg9AEwj_XPdxePBJmXky7eFPf5aVcWW9QVqn__QSu0PEY8BRrXQ0VV1xnJNixmAkM1DBEtnc6HMmCzBHE-b3Vm_mRACRUFyAZI9mGFf1aMB4OFw5rU9VZuSn3NFjwtBUfkqOCVc8_5HZOQTqbV";

  constructor(private http: HttpClient) {}
  getPaymentMethods(body: any, apiKey: string): Observable<any> {
    // this.getDifferences(this.apiKey, apiKey);
    // console.log(apiKey.substring(316))
    // console.log(this.apiKey.substring(316))
    // console.log(apiKey.substring(0,316))
    // console.log(this.apiKey.substring(0,316))
    // console.log(apiKey)
    // console.log(this.apiKey)
    const headers = new HttpHeaders({
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    });

    return this.http.post(`${this.baseURL}InitiatePayment`, body, {
      headers: headers,
    });
  }

  // getDifferences(str1: string, str2: string) {
  //   const diff = [];
  //   const len = Math.max(str1.length, str2.length);

  //   for (let i = 0; i < len; i++) {
  //     if (str1[i] !== str2[i]) {
  //       diff.push({
  //         index: i,
  //         char1: str1[i] || "",
  //         char2: str2[i] || "",
  //       });
  //     }
  //   }
  //   console.log(diff);

  //   return diff;
  // }

  ExecutePayment(data: any, apiKey): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    });
    return this.http.post(`${this.baseURL}ExecutePayment`, data, {
      headers: headers,
    });
  }
}
