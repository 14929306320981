<form [formGroup]="notifyMeForm" class="notify-me-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'Do you have a note about this product?' | translate}}
        </h4>
        <button class="close-button" (click)="dismissAllModal()">
            <i class="ti-close"></i>
        </button>
    </div>
    <div class="modal-body">

        
        <div class="form-group">
            <label for="email">{{'Email' | translate}}</label>
            <input
                [ngClass]="notifyMeForm.get('email').invalid && (notifyMeForm.get('email').dirty ||
                notifyMeForm.get('email').touched) ? 'error-class' : ''"
                type="email" class="form-control" formControlName="email" id="email"
                placeholder="{{'Please Enter Your Email Address' | translate}}" required=""
                name="email">
            <div class="error-messages-block">
                <span  *ngIf="notifyMeForm.get('email').hasError('required') && (notifyMeForm.get('email').dirty || notifyMeForm.get('email').touched)">{{'email is required' | translate}}</span>
                <span  *ngIf="notifyMeForm.get('email').hasError('email') && (notifyMeForm.get('email').dirty || notifyMeForm.get('email').touched)">{{'email is not valid' | translate}}</span>
            </div>
            
        </div>

        <div class="form-group">
            <label for="note">{{'Note' | translate}}</label>
            <textarea name="note" class="text-area-1" formControlName="note" rows="4" placeholder="{{'Here You can write your notes about this product. EX: Requested Quantity' | translate}}"></textarea>
        </div>

        

    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark confirm-button"
            (click)="confirmNotifyMeModal()">{{"confirm" | translate}}</button>
    </div>
</form>
