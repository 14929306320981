import { TokenService } from "./token.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, startWith, delay } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
// import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { CommonService } from "./common.service";
import { UserService } from "./user.service";
import { environment } from "../../../environments/environment";
import {
  Router,
  NavigationEnd,
  Event,
  NavigationStart,
  NavigationError,
  RoutesRecognized,
} from "@angular/router";
import { SettingsComponent } from "../components/settings/settings.component";
import { NotificationModalComponent } from "../components/modal/notification-modal/notification-modal.component";
import { MasterApiService } from "./master-api.service";

const state = {
  products: JSON.parse(localStorage["products"] || "[]"),
  wishlist: JSON.parse(localStorage["wishlistItems"] || "[]"),
  compare: JSON.parse(localStorage["compareItems"] || "[]"),
  cart: JSON.parse(localStorage["cartItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency: any = {
    name: "جنيه",
    currency: "جنيه",
    price: 1,
  };
  public OpenCart: boolean = false;
  public Products;
  private _url: string = this.common._hostName + "products.php?action=";
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();
  public deviceID: string = localStorage.getItem("Device-Id");
  data: any = new BehaviorSubject<any>("");
  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private common: CommonService,
    private router: Router,
    public notificationModalComponent: NotificationModalComponent,
    public tokenService: TokenService,
    private MasterApiService: MasterApiService
  ) {
    this.getAllContanctInfo();
  }

  getAllContanctInfo() {
    //   // this.common.getAllContanctInfo().subscribe((data) => {
    //   //   if (data.success) {
    //   //     const currentLang = localStorage.getItem("front-lang");
    //   //     if (data.data.currency[currentLang]) {
    //   //       this.Currency = {
    //   //         name: data.data.currency[currentLang],
    //   //         currency: data.data.currency[currentLang],
    //   //         price: 1,
    //   //       };
    //   //     }
    //   //     localStorage.setItem(
    //   //       "paymentInfo",
    //   //       JSON.stringify(this.common.getAllPaymentMethods(data.data))
    //   //     );
    //   //   }
    //   // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        const currentLang = localStorage.getItem("front-lang");
        if (dataContact.currency[currentLang]) {
          this.Currency = {
            name: dataContact.currency[currentLang],
            currency: dataContact.currency[currentLang],
            price: 1,
          };
        }
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.common.getAllPaymentMethods(dataContact))
        );
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
  public notifyOtherComponent() {
    this.notify.next();
  }
  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */
  updateData() {
    this.data.next("s");
  }
  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http
      .get<Product[]>("assets/data/products.json")
      .pipe(map((data) => data));

    this.Products.subscribe((next) => {
      localStorage["products"] = JSON.stringify(next);
    });
    return (this.Products = this.Products.pipe(
      startWith(JSON.parse(localStorage["products"] || "[]"))
    ));
  }

  listenToProductAvailability(productId: number, note = "", email) {
    let body = {
      product_id: productId,
      note: note,
      email: email,
    };
    return this.http.post<any>(this._url + "listenToProductAvailability", body);
  }
  // getNewestSeller(body , mode){
  //   return this.http.post<any>(
  //     this._url + "getProducts&start=0&aItemsPerPage=50&mode=" + mode,
  //     JSON.stringify(body)
  //   );
  // }
  // https://zahrastore.net/affiliate-develop/api-develop/web-api/products.php?start=0&aItemsPerPage=5&searchKey=&action=getProducts&searchCategoryId=1
  getModeSellerProducts(body, mode, aItemsPerPage = 25, start = 0) {
    return this.http.post<any>(
      this._url +
        "getProducts&start=" +
        start +
        "&aItemsPerPage=" +
        aItemsPerPage +
        "&mode=" +
        mode,
      JSON.stringify(body)
    );
  }
  getModeSellerProductsSearchCount(body, mode, aItemsPerPage = 25, start = 0) {
    return this.http.post<any>(
      this._url +
        "getSearchProductCount&start=" +
        start +
        "&aItemsPerPage=" +
        aItemsPerPage +
        "&mode=" +
        mode,
      JSON.stringify(body)
    );
  }
  getProductsPayParams(
    category_id,
    searchKey = "",
    brand = "",
    // aItemsPerPage=1000000,
    offer_id = "",
    body?: any,
    start = 0,
    itemsPerPage = 25,
    sort = "",
    type = "",
    filter_type = [],
    searchMode = "",
    mode = "",
    prod_ids = []
  ) {
    return this.http.post<any>(
      this._url +
        "getProducts" +
        "&start=" +
        start +
        "&aItemsPerPage=" +
        itemsPerPage +
        "&searchKey=" +
        searchKey +
        "&searchMode=" +
        searchMode +
        "&searchCategoryId=" +
        category_id +
        "&filter_type=" +
        filter_type +
        "&searchTrademark_id=" +
        brand +
        "&offer_id=" +
        offer_id +
        "&sort=" +
        sort +
        "&type=" +
        type +
        "&mode=" +
        mode,
      JSON.stringify(body)
    );
  }

  getSearchProductCount(
    category_id,
    searchKey = "",
    brand = "",
    // aItemsPerPage=1000000,
    offer_id = "",
    body?: any,
    start = 0,
    itemsPerPage = 25,
    sort = "",
    type = "",
    filter_type = [],
    searchMode = "",
    mode = ""
  ) {
    // console.log(JSON.stringify(body)); => {form_shape => [id, value], prices}
    return this.http.post<any>(
      this._url +
        "getSearchProductCount" +
        "&start=" +
        start +
        "&aItemsPerPage=" +
        itemsPerPage +
        "&searchKey=" +
        searchKey +
        "&searchMode=" +
        searchMode +
        "&searchCategoryId=" +
        category_id +
        "&filter_type=" +
        filter_type +
        "&searchTrademark_id=" +
        brand +
        "&offer_id=" +
        offer_id +
        "&sort=" +
        sort +
        "&type=" +
        type +
        "&mode=" +
        mode,
      // "&aItemsPerPage=" +
      // aItemsPerPage +
      JSON.stringify(body)
    );
  }

  getFiltersOfProducts(category_id = "", trademark_id = "") {
    return this.http.get<any>(
      this._url +
        "getFiltersOfProducts_custom&searchCategoryId=" +
        category_id +
        "&searchTrademark_id=" +
        trademark_id
    );
  }

  getFormShapes(category_id = "") {
    return this.http.get<any>(
      this._url + "get_form_shapes&searchCategoryId=" + category_id
    );
  }
  getBreadcrumbFormShape(formShape: any) {
    return this.http.get<any>(
      this._url + "getBreadcrumbFormShape&searchKey=" + formShape
    );
  }
  getNewestProducts() {
    return this.http.post<any>(
      this._url + "getProducts&sort=id&start=0&aItemsPerPage=25",
      null
    );
  }

  getHomeProducts() {
    return this.http.post<any>(
      this._url +
        "getProducts&start=0" +
        "&aItemsPerPage=" +
        50 +
        "&searchKey=",
      null
    );
  }

  getProductDetails(product_id) {
    return this.http.get<any>(
      this._url + "getProductDetails&product_id=" + product_id
    );
  }
  getProductTitle(product_id) {
    return this.http.get<any>(
      this._url + "getProductTitle&product_id=" + product_id
    );
  }
  getProductsAutoComplete(searchKey, start = 0, aItemsPerPage = 10, mode) {
    return this.http.post<any>(
      this._url +
        "getProductsAutoComplete&start=" +
        start +
        "&aItemsPerPage=" +
        aItemsPerPage +
        "&searchKey=" +
        searchKey +
        "&mode=" +
        mode,
      null
    );
  }

  addSearchProductReports(searchKey, total_count) {
    let body = {
      searchCategoryId: "",
      searchKey: searchKey,
      total_count: total_count,
    };
    return this.http.post<any>(this._url + "addSearchProductReports", body);
  }

  // private get products(): Observable<Product[]> {
  //   this.Products = this.http.get<Product[]>('https://zahrastore.net/affiliate-develop/api-develop/web-api/products.php?start=0&aItemsPerPage=5&searchKey=&action=getProducts&searchCategoryId=1').pipe(map(data => data));
  //   this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
  //   return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  // }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(
      map((items) => {
        return items.find((item: any) => {
          return item.title.replace(" ", "-") === slug;
        });
      })
    );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    
    const wishlistItem = state.wishlist.find((item) => (item.id == product.id));
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
      });
    }
    if (localStorage.getItem("front-lang") == "en") {
      this.toastrService.success("Product has been added in wishlist.");
    } else {
      this.toastrService.success("تم إضافة المنتج الى قائمة المفضلة ");
    }

    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    if (localStorage.getItem("front-lang") == "en") {
      this.toastrService.success("Product has been added in wishlist.");
    } else {
      this.toastrService.success("تم إضافة المنتج الى قائمة المفضلة ");
    }
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  addToCart1(product: any) {
    let obj = {
      product_id: product["id"],
      quantity: product["quantity"],
      product_type_name: product["stores"][0]["name"],
      offer_id: product["offer_id"],
    };

    let body = JSON.stringify(obj);

    //console.log"addtocart1", product);

    // return this.http.post<any>(this.common._cartsUrl + "addToCart", body);
  }
  

  // Add to Cart
  public addToCart(product, optionsItemSelected = []): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (product?.stores.length > 1 && product["selected_store"] != true) {
        this.router.navigate(["/shop/product/" + product.id]);
      } else {
        if (localStorage.getItem("clientToken") != null || this.deviceID) {
          product["selected_store"] = true;
          product["store_id"] =
            optionsItemSelected.length == 0
              ? product["stores"][0]["product_type_id"]
              : optionsItemSelected["store_id"];

          product.quantity = product.quantity >= 1 ? product.quantity : 1;
          let product_amount =
            optionsItemSelected.length == 0
              ? product["stores"][0]["store_amounts_product"]
              : optionsItemSelected["store_amounts_product"];
          if (product.quantity > product_amount) {
            if (localStorage.getItem("front-lang") == "en") {
              this.toastrService.error("Product not available");
            } else {
              this.toastrService.error(" المنتح غير متوفر ");
            }
            resolve(false);
          } else {
            let body = {
              product_id: product["id"] ,
              quantity: product["quantity"],
              product_type_name:
                optionsItemSelected.length == 0
                  ? product["stores"][0]["name"]
                  : optionsItemSelected["name"],
              offer_id: product["offer_id"] ? product["offer_id"] : "",
              store_title:
                optionsItemSelected.length == 0
                  ? product["stores"][0]["store_title"]
                  : optionsItemSelected["store_title"],
              api_id_to: product["api_id_to"],
              cart_group_id:
                localStorage.getItem("cart_group_id") !== "0"
                  ? localStorage.getItem("cart_group_id")
                  : "0",
              api_product_id: product["api_product_id"],
            };
    
            this.common.addToCart(body).subscribe((data) => {
              if (data.success) {
                console.log(data.success);
                this.common.UpdatedSetting.next("added");
                this.common.Updatedheaderone.next("added");
                resolve(true);
              } else {
                this.common.getCart();
                resolve(false);
              }
            });
          }
        } else {
          if (localStorage.getItem("front-lang") == "en") {
            this.toastrService.error("logged in please!");
          } else {
            this.notificationModalComponent.ngAfterViewInit();
          }
          resolve(false);
        }
      }
    });
  }

  // public addProductToCart(product, toast = true): Promise<boolean> {
  //   return new Promise<boolean>((resolve) => {
  //     if (product?.stores.length > 1 && product["selected_store"] != true) {
  //       this.router.navigate(["/shop/product/" + product.id]);
  //     } else {
  //       if (localStorage.getItem("clientToken") != null || this.deviceID) {
  //         product["selected_store"] = true;
  //         product["product_type_id"] = product["stores"][0]["product_type_id"];

  //         product.quantity = product.quantity >= 1 ? product.quantity : 1;
  //         if (
  //           product.quantity > product["stores"][0]["store_amounts_product"]
  //         ) {
  //           if (localStorage.getItem("front-lang") == "en") {
  //             this.toastrService.error("Product not available");
  //           } else {
  //             this.toastrService.error(" المنتح غير متوفر ");
  //           }
  //           resolve(false);
  //         } else {
  //           let body = {
  //             product_id: product["id"],
  //             quantity: product["quantity"],
  //             product_type_name: product["stores"][0]["name"],
  //             offer_id: product["offer_id"] ? product["offer_id"] : "",
  //             store_title: product["stores"][0]["store_title"],
  //             api_id_to: product["api_id_to"],
  //             cart_group_id:
  //               localStorage.getItem("cart_group_id") !== "0"
  //                 ? localStorage.getItem("cart_group_id")
  //                 : "0",
  //             api_product_id: product["api_product_id"],
  //           };
  //           this.common.addToCart(body).subscribe((data) => {
  //             if (data.success) {
  //               if (toast) {
  //                 if (localStorage.getItem("front-lang") == "en") {
  //                   this.toastrService.success(
  //                     "Product has been added in cart."
  //                   );
  //                 } else {
  //                   this.toastrService.success("تم إضافة المنتج الى السلة ");
  //                 }
  //               }
  //               console.log(data.success);
  //               // Get New product.store_amounts From DB
  //               this.common.UpdatedSetting.next("added");
  //               this.common.Updatedheaderone.next("added");
  //               resolve(true); // Resolve with true as addition successful
  //             } else {
  //               this.common.getCart();
  //               resolve(false); // Resolve with false as addition failed
  //             }
  //           });
  //         }
  //       } else {
  //         if (localStorage.getItem("front-lang") == "en") {
  //           this.toastrService.error("logged in please!");
  //         } else {
  //           this.notificationModalComponent.ngAfterViewInit();
  //           // this.tokenService.getDeviceId();
  //         }
  //         resolve(false); // Resolve with false as addition failed
  //       }
  //     }
  //   });
  // }

  // Update Cart Quantity
  public updateCartQuantity(
    product: Product,
    quantity: number
  ): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      }
    });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100;
          }
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                      START OF BROWSE HISTORY FUNCTIONS                     */
  /* -------------------------------------------------------------------------- */

  // addBrowseProductHistory
  addBrowseProductHistory(productId: any) {
    let obj = {
      product_id: productId,
      app_version: "web",
    };
    let body = JSON.stringify(obj);
    return this.http.post<any>(this._url + "addBrowseProductHistory", body);
  }

  // GET ALL BROWSE HISTORY LIST
  allBrowseProductHistory(start = 0, aItemsPerPage = 10) {
    return this.http.get<any>(
      this._url +
        "allBrowseProductHistory&start=" +
        start +
        "&aItemsPerPage=" +
        aItemsPerPage
    );
  }

  // CLEAR ALL BROWSE HISTORY FROM SERVER
  deleteAllBrowseProductHistory() {
    return this.http.get<any>(this._url + "deleteAllBrowseProductHistory");
  }

  deleteBrowseProductHistory(productId: any) {
    let body = {
      // history_id: historyId,
      product_id: productId,
    };
    return this.http.post<any>(
      this._url + "deleteBrowseProductHistory",
      JSON.stringify(body)
    );
  }

  /* --------------------- END OF BROWSE HISTORY FUNCTIONS -------------------- */

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(
      map((product) =>
        product.filter((item: Product) => {
          if (!filter.length) return true;
          const Tags = filter.some((prev) => {
            // Match Tags
            if (item.tags) {
              if (item.tags.includes(prev)) {
                return prev;
              }
            }
          });
          return Tags;
        })
      )
    );
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  getProductQuantityDiscount(product_id: any) {
    return this.http.post<any>(this._url + "getProductQuantityDiscount", {
      product_id: product_id,
    });
  }
  getProductsRelated(product_id: any) {
    return this.http.post<any>(this._url + "getProductsRelated", {
      product_id: product_id,
    });
  }
  getProductSimilar(product_id: any) {
    return this.http.post<any>(this._url + "getProductSimilar", {
      product_id: product_id,
    });
  }
}
