import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpInterceptor } from '@angular/common/http';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { environment } from '../../../environments/environment';
const state = {
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}

@Injectable({
  providedIn: "root",
})
export class OrderService {
  public _ordersUrl = this.common._hostName + "orders.php?action=";
  public _invoicesUrl = this.common._hostName + "invoices.php?action=";
  constructor(
    private router: Router,
    private http: HttpClient,
    private common: CommonService
  ) {}

  addOrder(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._ordersUrl + "addOrder", body);
  }
  //?==============addInVoice==============//
  addInvoice(data, apiIdTo, secretKey) {
    let body = JSON.stringify(data);
    let url =
      "https://api-develop.malyaan.net/secret-api/invoices-api.php?action=";

    if (apiIdTo == 0) {
      return this.http.post<any>(this._invoicesUrl + "addInvoice", body);
    } else {
      return this.http.post<any>(
        url + "addInvoice&secret_key=" + secretKey + "&set_from=web",
        body
      );
    }
  }
  GetStatusOfSaleInvoice(id) {
    let body={
      id :id
    }
    let data = JSON.stringify(body);
    return this.http.post<any>(this._invoicesUrl + "GetStatusOfSaleInvoice", body);
  }
  // addExternalInvoice(data , secertKey ){

  //   return this.http.post<any>(url + secertKey + '&set_from=web' , data );
  // }
  invoiceRestrictions(order_id: number) {
    return this.http.get<any>(
      this._invoicesUrl + "invoiceRestrictions&id=" + order_id
    );
  }

  addDirectRequest(body) {
    return this.http.post<any>(this._invoicesUrl + "addDirectRequest", body);
  }
  
  cancelInvoice(body) {
    return this.http.post<any>(this._invoicesUrl + "CancelInvoice", body);
  }

  getInvoices() {
    return this.http.get<any>(this._invoicesUrl + "getInvoices");
  }
  editInvoice(order) {
    // if (
    //   localStorage.getItem("clientToken") != null &&
    //   localStorage.getItem("clientToken") != ""
    // ) {
    //   var headers = new HttpHeaders({
    //     "Content-Type": "application/json",
    //     "front-lang": localStorage.getItem("front-lang"),
    //     "affiliator-token": environment.affiliator_token,
    //     "user-token": localStorage.getItem("clientToken"),
    //   });
    // } else {
    //   var headers = new HttpHeaders({
    //     "Content-Type": "application/json",
    //     "front-lang": localStorage.getItem("front-lang"),
    //     "affiliator-token": environment.affiliator_token,
    //   });
    // }
    let url =
      "https://api-develop.malyaan.net/secret-api/invoices-api.php?action=";
    let obj = {
      invoice_id: order?.id,
      api_id_to: order.api_id_to > 0 ? order?.api_id_to : null,
      api_invoice_id: order.api_id_to > 0 ? order?.api_invoice_id : null,
      cart_group_id: order?.cart_group_id,
      account_id:
        order.api_id_to > 0 ? order?.details_store_external.account_id : null,
    };
    let body = JSON.stringify(obj);
    if (order.api_id_to == 0) {
      return this.http.post<any>(this._invoicesUrl + "editInvoice", body);
    } else {
      return this.http.post<any>(
        url +
          "editInvoice&secret_key=" +
          order?.details_store_external.secret_key +
          "&set_from=web",
        body
      );
    }
  }

  deleteOrder(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._ordersUrl + "deleteOrder", body);
  }

  deleteInvoice(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._invoicesUrl + "deleteInvoice", body);
  }

  getMyOrders() {
    return this.http.get<any>(
      this._ordersUrl +
        "getOrders&start=0" +
        "&aItemsPerPage=" +
        500 +
        "&searchDateFrom=&searchDateTo=&searchStatus="
    );
  }

  getMyInvoices() {
    return this.http.get<any>(
      this._invoicesUrl +
        "getInvoices&start=0" +
        "&aItemsPerPage=" +
        500 +
        "&searchDateFrom=&searchDateTo=&searchStatus=&searchId="
    );
  }

  getOrderDetails(order_id) {
    return this.http.get<any>(
      this._ordersUrl + "getOneOrderDetails&order_id=" + order_id
    );
  }

  getOneInvoice(invoice_id) {
    return this.http.get<any>(
      this._invoicesUrl +
        "getOneInvoice&invoice_id=" +
        invoice_id +
        "&front-lang=" +
        localStorage.getItem("front-lang")
    );
  }

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
      shippingDetails: details,
      product: product,
      orderId: orderId,
      totalAmount: amount,
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    localStorage.removeItem("cartItems");
    this.router.navigate(["/shop/checkout/success", orderId]);
  }
}
