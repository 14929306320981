// import { isPlatformBrowser } from '@angular/common';
// import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

// @Component({
//   selector: 'app-countdown-timer',
//   templateUrl: './countdown-timer.component.html',
//   styleUrls: ['./countdown-timer.component.scss']
// })
// export class CountdownTimerComponent implements OnInit {

//   @Input() offerEndDate: any;

//   isBrowser: any;

//   timeLeftTillOfferEnds: number;

//   constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
//     this.isBrowser = isPlatformBrowser(this.platformId);
//   }

//   ngOnInit(): void {
//     // console.log('this.offerEndDate', this.offerEndDate);
//     if (isPlatformBrowser(this.platformId)) {
//       this.getTimeLeftOfferEnds(this.offerEndDate);
//     }

//   }

//   getTimeLeftOfferEnds(dateEnd){
//     var x = new Date();
//     var y = new Date(dateEnd);
//     let seconds = Math.abs(x.getTime() - y.getTime())/1000;

//     // seconds = ~~seconds;
//     // console.log('number of seconds ', seconds);
//     this.timeLeftTillOfferEnds = seconds;
//   }

// }

import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "app-countdown-timer",
  templateUrl: "./countdown-timer.component.html",
  styleUrls: ["./countdown-timer.component.scss"],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() offerEndDate: any;
  @Input() title: any;
  isBrowser: any;

  timeLeft: { hours: number; minutes: number; seconds: number } = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  private interval;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.calculateTimeLeftTillOfferEnds(this.offerEndDate);
    }
  }

  calculateTimeLeftTillOfferEnds(dateEnd: string) {
    const targetDate = new Date(dateEnd);
    targetDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999 (end of the day)

    this.interval = setInterval(() => {
      const now = new Date().getTime(); // Get the current time in milliseconds
      const timeDiff = targetDate.getTime() - now; // Difference in milliseconds

      if (timeDiff > 0) {
        this.calculateTimeLeft(timeDiff); // Calculate time breakdown
      } else {
        clearInterval(this.interval); // Stop when time is up
        this.timeLeft = { hours: 0, minutes: 0, seconds: 0 }; // Reset to zero
      }
    }, 1000); // Update every second
  }

  // Function to calculate hours, minutes, and seconds from timeDiff
  calculateTimeLeft(timeDiff: number) {
    const totalSeconds = Math.floor(timeDiff / 1000); // Convert milliseconds to seconds
    const hours = Math.floor(totalSeconds / 3600); // Hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Minutes
    const seconds = totalSeconds % 60; // Seconds

    this.timeLeft = { hours, minutes, seconds }; // Set the time
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval); // Clear the interval when component is destroyed
    }
  }
}
