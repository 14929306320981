<app-breadcrumb [title]="product?.title" [breadcrumb]="'Product'"></app-breadcrumb>
<section class="section-b-space">
    <div class="collection-wrapper ratio_asos">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row product_image_4">
                        <div class="col-6" *ngFor="let image of product.images; index as i">
                            <div>
                                <img [defaultImage]="'assets/images/product/placeholder.jpg'" 
                                     [lazyLoad]="image.src" 
                                     [alt]="image.alt" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product?.title }}</h2>
                        <h4>
                            <del *ngIf="product?.discount">{{ product?.price * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}</del>
                            <span>{{ product?.discount }}% off</span>
                        </h4>
                        <h3>
                            {{ (product?.price | discount:product) * productService.Currency?.price | currency: productService.Currency?.currency:'symbol' }}
                        </h3>
                        <ul class="color-variant" *ngIf="Color(product?.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product?.variants); index as i" 
                                [ngStyle]="{'background-color': color}">
                            </li>
                        </ul>
                        <app-stock-inventory [stock]="product.stock"></app-stock-inventory>
                        <div class="product-description border-product">
                            <h6 class="product-title size-text" *ngIf="Size(product.variants).length">
                                select size
                                <span>
                                  <a href="javascript:void(0)" (click)="SizeChart.openModal()">size chart</a>
                                </span>
                            </h6>
                            <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li [class.active]="selectedSize == size" *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)" (click)="selectSize(size)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div>
                            <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5>
                            <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                        <i class="ti-angle-left"></i>
                                    </button>
                                </span>
                                <input type="text" name="quantity" class="form-control input-number" [value]="counter" disabled/>
                                <span class="input-group-prepend">
                                    <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                        <i class="ti-angle-right"></i>
                                    </button>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">add to cart</a>
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="buyNow(product)">buy now</a>
                        </div>
                        <div class="border-product">
                            <h6 class="product-title">product details</h6>
                            <p>{{ product.description.substring(0, 200)+'...' }}</p>
                        </div>
                        <div class="border-product">
                        <h6 class="product-title">share it</h6>
                        <div class="product-icon">
                            <app-social></app-social>
                            <form class="d-inline-block">
                                <button class="wishlist-btn" (click)="addToWishlist(product)">
                                    <i class="fa fa-heart"></i>
                                    <span class="title-font">Add To WishList</span>
                                </button>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="tab-product m-0">
                <div class="row">
                    <div class="col-sm-12 col-lg-12">
                    <ngb-tabset [type]="'tabs'" class="nav-material">
                        <ngb-tab>
                            <ng-template ngbTabTitle>Description</ng-template>
                            <ng-template ngbTabContent>
                                <p>{{ product.description }}</p>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>Video</ng-template>
                            <ng-template ngbTabContent>
                                <div class="mt-3 text-center">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>Write Review</ng-template>
                            <ng-template ngbTabContent>
                                <form class="theme-form">
                                <div class="form-row">
                                    <div class="col-md-12 ">
                                    <div class="media">
                                        <label>Rating</label>
                                        <div class="media-body ml-3">
                                        <div class="rating three-star">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="name">Name</label>
                                        <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="email">Email</label>
                                        <input type="text" class="form-control" id="email" placeholder="Email" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="review">Review Title</label>
                                        <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="review">Review Title</label>
                                        <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                                    </div>
                                </div>
                                </form>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<app-size-modal #sizeChart [product]="product"></app-size-modal>