import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { getMessaging, getToken } from 'firebase/messaging';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  // getToken(getMessaging(), {vapidKey: environment.firebase.vapidKey});
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
  
});
