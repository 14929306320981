import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  public _offersUrl = "https://my.malyaan.net/api/web-api/offers.php?action=";
  public offersImagesUrl = this.commonService._ImageUrl + "offers/"

  constructor(private http: HttpClient, public commonService: CommonService) { }

  getOneOffer(offerId: number){
    return this.http.get<any>(
      this._offersUrl + "getOneOffer" + "&id=" + offerId
    );
  }
}
