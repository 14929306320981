<!-- Start Categories List -->
<ngb-accordion class="accordion1" #acc="ngbAccordion">
    <ngb-panel>
        <ng-template ngbPanelTitle>
            <div class="d-flex justify-content-between">
                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                    {{'category' | translate}}
                </div>
                <div class="d-flex"><i class="ti-angle-down"></i></div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="collection-brand-filter">
                <ul class="category-list">
                    <li *ngFor="let category of categories">
                        <a [routerLink]="['/shop/category/'+category.id +'/'+category.ser_name]">
                            {{ category.ser_name }}
                        </a>
                    </li>
                </ul>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>


<!-- <div class="collection-collapse-block border-0" [class.open]="collapse">
    <div class="d-flex justify-content-between">
        <h3 class="collapse-block-title" (click)="collapse = !collapse">{{'category' | translate}}</h3>
        <div class="d-flex"><i class="ti-angle-down"></i></div>
    </div>
    
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list">
                <li *ngFor="let category of categories">
                    <a [routerLink]="['/shop/collection/infinitescroll/'+category.id +'/'+category.ser_name]">
                        {{ category.ser_name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Categories List -->