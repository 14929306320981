<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">مرحبا بك ❤</h4>
        <!-- <button type="button" class="btn-close border-0" aria-label="Close" (click)="d('Cross click')"></button> -->
    </div>
    <div class="modal-body">
        <p>يرجى السماح بالاشعارات لتتمكن من اضافة المنتجات</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="requestPermission()">موافق</button>
        <button type="button" class="btn close" aria-label="Close" (click)="d('Cross click')">الغاء</button>
    </div>
</ng-template>

<button class="btn btn-lg btn-outline-primary" hidden id="modal" (click)="open(content)">Launch demo modal</button>