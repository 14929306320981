<div class="product-top-filter">
    <div class="row">
        <div class="col-12">
            <div class="product-filter-content w-100 justify-content-end">
                <!-- <div class="collection-view">
                    <ul>
                        <li (click)="setLayoutView('grid-view')">
                            <i class="fa fa-th grid-layout-view"></i>
                        </li>

                        <li (click)="setLayoutView('list-view')">
                            <i class="fa fa-list-ul list-layout-view"></i>
                        </li>
                    </ul>
                </div>
                <div class="collection-grid-view" *ngIf="layoutView == 'grid-view'">
                    <ul>
                        <li (click)="setGridLayout('col-lg-6')">
                            <img src="assets/images/icon/2.png" class="product-2-layout-view">
                        </li>
                        <li (click)="setGridLayout('col-lg-4')">
                            <img src="assets/images/icon/3.png" class="product-3-layout-view">
                        </li>
                        <li (click)="setGridLayout('col-lg-3')">
                            <img src="assets/images/icon/4.png" class="product-4-layout-view">
                        </li>
                    </ul>
                </div> -->

                <!-- <div class="view-grid-block "> -->
                <!-- <div class="right-grid-block">
                        <div (click)="setLayoutView('grid-view')" style="padding-top: 2px;">
                            <i class="fa fa-th grid-layout-view"></i>
                        </div>
                        <div (click)="setLayoutView('list-view')">
                            <i class="fa fa-list-ul list-layout-view"></i>
                        </div>
                    </div> -->
                <!-- <div class="left-grid-block">
                        <div (click)="setGridLayout('col-lg-4')">
                            <i class="ti-layout-grid3-alt"></i>
                        </div>
                        <div (click)="setGridLayout('col-lg-6')">
                            <i class="ti-layout-grid2-alt"></i>
                            
                        </div>
                    </div> -->

                <!-- </div> -->
                <!-- <div class="search-count">
                    <h5> 1-{{ products.length }} {{ paginate.totalItems }} </h5>
                </div> -->
                <div class="product-page-filter">
                    <div class="inner">
                        <select (change)="sorting($event)" style="font-weight: 600;border-color: #dddddd;">
                            <option style="font-weight: 600;" value="">{{'Sorting Items' | translate}}</option>
                            <option style="font-weight: 600;" value="a-z" [selected]="sortBy == 'a-z'">
                                {{'AlphabeticallyA-Z'| translate}}</option>
                            <option style="font-weight: 600;" value="z-a" [selected]="sortBy == 'z-a'">
                                {{'AlphabeticallyZ-A'| translate}}</option>
                            <option style="font-weight: 600;" value="low" [selected]="sortBy == 'low'">
                                {{'PriceLowtoHigh'| translate}}</option>
                            <option style="font-weight: 600;" value="high" [selected]="sortBy == 'high'">
                                {{'PriceHightoLow'| translate}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="settings-menu-layout">
    <div class="settings-other-buttons-layout">
        <div ngbDropdown class="d-inline-block sort-dropdown">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                {{'sort' | translate}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem value="a-z" (click)="sorting($event)">
                    <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>
                    {{'AlphabeticallyA-Z'| translate}}
                </button>
                <button ngbDropdownItem value="z-a" (click)="sorting($event)">
                    <i class="fa fa-sort-alpha-desc" aria-hidden="true"></i>
                    {{'AlphabeticallyZ-A'| translate}}
                </button>
                <button ngbDropdownItem value="low" (click)="sorting($event)">
                    <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                    {{'PriceLowtoHigh'| translate}}
                </button>
                <button ngbDropdownItem value="high" (click)="sorting($event)">
                    <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    {{'PriceHightoLow'| translate}}
                </button>
            </div>
        </div>
        <div ngbDropdown class="d-inline-block display-dropdown"
            [placement]="commonService.isRtl ? 'bottom-right' : 'bottom-left'">
        </div>
    </div>


    <button class="btn filter-button" (click)="toggleMobileSidebar()">
        {{'filter' | translate}}
        <i class="fa fa-filter" aria-hidden="true"></i>
    </button>

</div>