import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  constructor(public appComponent: AppComponent) { }

  ngOnInit(): void {
    // alert(this.appComponent.tokenID);
  }

}
