<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'home'
                                | translate}}</a></li>
                        <li class="breadcrumb-item " aria-current="section">
                            <a [routerLink]="['/elements/trademarks']">
                                {{'brands' | translate}}
                            </a>

                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{brand_name}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<!-- <div class="page-title">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>{{'brands' | translate}} / {{brand_name}}</h2>
            </div>
        </div>
    </div>
</div> -->
<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <h1 class="category-up-title">
                    {{brand_name}}
                </h1>
            </div>
        </div>
    </div>
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-2">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->

                        <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                            <span class="filter-back">

                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                                {{ 'Back' | translate }}&nbsp;&nbsp;
                            </span>
                        </div>

                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->

                        <!-- END OF CUSTOM CATEGORY FILTER NEW -->


                        <div class="collection-filter-block">

                            <!-- FORM SHAPE FILTER -->
                            <ngb-accordion class="accordion1" #acc="ngbAccordion">
                                <ng-container *ngFor="let formshape of allDatafilter; let i = index">
                                    <ngb-panel
                                        id="{{ 'formshapepanel' + ( formshape.id ? formshape.id : formshape.mode )}}"
                                        *ngIf="(formshape.type == 'select' || formshape.type == 'm_select')">
                                        <ng-template ngbPanelTitle>
                                            <div class="d-flex justify-content-between">
                                                <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                                    {{formshape.label | translate}}
                                                </div>
                                                <div class="d-flex"><i class="ti-angle-down"></i></div>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <ng-container *ngFor="let formoption of formshape.options; let j = index">
                                                <div *ngIf="formoption.label" class="accordion-content">

                                                    <input type="checkbox" id="{{ 'option_' + i + '_' + j }}"
                                                        [disabled]="disabledOption"
                                                        (change)="checkValueForm($event, formshape.id, formoption.value, formshape.mode, formoption.label)"
                                                        [checked]="formShapeExists(formshape.id, formoption.value, formshape.mode)">
                                                    &nbsp;
                                                    <label for="{{ 'option_' + i + '_' + j }}">
                                                        {{formoption.label}}
                                                    </label>

                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ngb-panel>
                                </ng-container>
                            </ngb-accordion>

                        </div>

                    </div>
                </div>



                <div class="collection-content col">
                    <div class="page-main-content">

                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <div class="top-banner-wrapper">
                                    <a [routerLink]="['/shop/collection/left/sidebar']">
                                        <img src="assets/images/collection-banner.jpg" class="img-fluid " alt="">
                                    </a>
                                    <div class="top-banner-content small-section">
                                        <h4>fashion</h4>
                                        <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </h5>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div> -->
                                <div class="row filters-button-layout">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i>
                                                {{'filters' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="filter-tags-block d-flex justify-content-between ">
                                    <!-- <div class="filter-tags-block border-test2"> -->
                                    <div class="d-flex justify-content-between align-content-stretch w-100">
                                        <div class="buttons-block">

                                            <!-- Filter Categories -->
                                            <div class="tag-button" *ngFor="let categoryTag of categoryFilterTags"
                                                (click)="removeCategoryTag(categoryTag.id)">
                                                <span>
                                                    {{ categoryTag.ser_name }}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>

                                            <!-- Filter status -->
                                            <div class="tag-button" *ngFor="let avilableTag of statusFilterTags"
                                                (click)="removeStatusTag(avilableTag)">
                                                <span *ngIf="avilableTag === 'soon'">
                                                    قريباً
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="avilableTag === 'in_stock'">
                                                    متوفر
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="avilableTag === 'discounts'">
                                                    خصومات
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="avilableTag === 'offers'">
                                                    عروض
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>

                                            <!-- Filter Form Shapes -->
                                            <div class="tag-button" *ngFor="let formTag of formShapesFilterTags"
                                                (click)="removeFormTag(formTag.value, formTag.formId)">
                                                <span>
                                                    {{formTag.label}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>

                                            <!-- Filter Prices -->
                                            <div class="tag-button" *ngFor="let formTag of pricesFilterTags"
                                                (click)="removePriceTag(formTag.from, formTag.to)">
                                                <span>
                                                    {{formTag.from}} - {{formTag.to}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="ml-2 border-light d-flex align-items-center">
                                        <div class="clear-all-tags " *ngIf="formShapesFilterTags.length > 0 || statusFilterTags.length > 0 || pricesFilterTags.length > 0
                                            || categoryFilterTags.length > 0">
                                            <span (click)="removeAllTags()">
                                                {{"clear all" | translate}}
                                            </span>
                                        </div>
                                        <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView"
                                            [sortBy]="sortBy" (mobileSidebarChange)="toggleMobileSidebar()"
                                            (sortedBy)="sortByFilter($event)">
                                        </app-grid>
                                    </div>

                                </div>

                                <!-- <ul class="product-filter-tags">
                                    <li class="mr-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length>
                                        0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            Clear all
                                        </a>
                                    </li>
                                </ul> -->

                                <div class="collection-product-wrapper " id="products">

                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" style=" margin: 5px;" [ngClass]="grid" *ngFor="let
                                                product of allShownProducts">
                                                <!-- <div class="product-box"> -->
                                                <app-product-box-one [product]="product"
                                                    [currency]="productService?.Currency" [thumbnail]="false"
                                                    [cartModal]="true">
                                                </app-product-box-one>
                                                <!-- </div> -->
                                            </div>
                                            <div class="mt-5 text-center col-sm-12 section-b-space no-found"
                                                *ngIf="finished &&!allItems.length && !allShownProducts.length">
                                                <img src="assets/images/empty-search1.png" class="mb-4 img-fluid">
                                                <h3>{{'Sorry Couldn find the product' | translate}}</h3>
                                                <p></p>
                                                <a [routerLink]="'/'" class="btn btn-solid">{{'continue shopping' |
                                                    translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="300"
                                        (scrolled)="onScroll()" *ngIf="allItems.length && !loading">
                                    </div>
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="p-0 container-fluid">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="mt-3 mb-3 infinite-scrolling d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="!finished">
                                                            <div class="loading-more active" *ngIf="finished
                                                                &&!allItems.length">{{'No More Products' | translate}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->