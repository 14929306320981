import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ProductService } from "../../services/product.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() searchBreadcrumb: string = "";
  @Input() breadcrumb: string;
  @Input() formShapesMode: boolean;
  @Input() query: string;
  @Input() border: boolean = true;
  FormShapesLabels: any;

  constructor(private productService: ProductService) {}
  ngOnInit(): void {
    // console.log(this.query);

    if (this.formShapesMode) {
      this.productService
        .getBreadcrumbFormShape(this.query)
        .subscribe((data) => {
          this.FormShapesLabels = data.data;
          // console.log(this.FormShapesLabels);
        });
    }
  }
}
