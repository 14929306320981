import { FormBuilder } from "@angular/forms";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { UserService } from "src/app/shared/services/user.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { MasterApiService } from "src/app/shared/services/master-api.service";

@Component({
  selector: "app-fast-login",
  templateUrl: "./fast-login.component.html",
  styleUrls: ["./fast-login.component.scss"],
})
export class FastLoginComponent implements OnInit {
  logourl = "";
  imageurl = "";
  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;
  step6: boolean = false;
  country_code: any;
  image_mobile: string;
  _image_code: string;
  public settings: any;
  email_data: string = "";
  mobile_data: string = "";
  verify_code: string = "";
  email_verify: string = "";
  user_name: string = "";
  last_name: string = "";
  password: string = "";
  confirmPassword: string = "";
  @Input() errorOccurred = false;
  errorOccurredMessage: string;
  successEmail: boolean = false;
  public errorSuccessEmailMessage = "";
  successUser_name: boolean = false;
  successLast_name: boolean = false;
  public errorUserNameMessage = "";
  public errorLastNameMessage = "";

  successMobile: boolean = false;
  errorMobileMessage = "";
  successPassword: boolean = false;
  successconfirmPassword: boolean = false;
  errorPasswordMessage = "";
  errorconfirmPasswordMessage = "";
  phoneplaceholder: string = "";
  mobileLength: number = 0;
  keyLength: number = 0;
  valid_mobile: string = "";
  mobile_keys = [];
  isMobileLogin: boolean = true;
  isEmailLogin: boolean = false;
  mobilePattern = /^[0-9]+$/;
  mobileIsValid: boolean = false;
  mobileIsInValid: boolean = false;
  @Output() step5Reached = new EventEmitter<void>();
  @Output() stepSuccessReached = new EventEmitter<void>();

  constructor(
    public Common: CommonService,
    private user: UserService,
    private router: Router,
    private translateService: TranslateService,
    private MasterApiService: MasterApiService
  ) {
    this.imageurl = this.Common._ImageUrl + "front_images/";
    this._image_code = this.Common._imageUrlPlaces + "places/";
  }

  ngOnInit(): void {
    this.getAllContanctInfo();

    this.step1 = true;
  }
  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.settings = dataContact;
        this.logourl = this.imageurl + this.settings["front_logo"];
        if (
          parseInt(this.settings?.register_client_on_email) == 1 &&
          parseInt(this.settings?.register_client_on_mobile) == 0
        ) {
          this.isEmailLogin = true;
          console.log(this.isEmailLogin);
        }

        this.country_code = dataContact.country_info.country_code;
        this.image_mobile = this._image_code + dataContact.country_info.image;
        this.mobile_keys = dataContact.country_info.mobile_keys.split(",");
        this.mobileLength = parseInt(dataContact.country_info.mobile_length);
        this.keyLength = this.mobile_keys[0].length;
        const len = this.mobileLength - this.keyLength;
        this.phoneplaceholder = this.mobile_keys[0];
        for (let index = 0; index < len; index++) {
          this.phoneplaceholder += "x";
        }
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Common.getAllPaymentMethods(dataContact))
        );
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
  @ViewChildren("inputFields") inputFields!: QueryList<any>;

  calculateTextColor(): string {
    return this.Common.tinyColor("var(--theme-default)");
  }

  toggleLoginType(isEmail: boolean) {
    this.isMobileLogin = isEmail;
    this.isEmailLogin = !isEmail;
    this.errorOccurredMessage = "";
  }

  moveToInput(event: any, index: number) {
    const target = event.target;
    const value = target.value;

    if (event.key === "Backspace" && value.length === 0) {
      if (index > 0) {
        this.inputFields.toArray()[index - 1].nativeElement.focus();
      }
    }

    if (value.length === 1) {
      if (index < this.inputFields.length - 1) {
        this.inputFields.toArray()[index + 1].nativeElement.focus();
      }
    }
  }

  totalSeconds: number = 60;
  timer: any;

  startTimer(): void {
    this.totalSeconds = 60;
    this.timer = setInterval(() => {
      this.totalSeconds--;
      if (this.totalSeconds <= 0) {
        this.stopTimer();
        console.log(this.totalSeconds);
      }
    }, 1000);
  }

  stopTimer(): void {
    clearInterval(this.timer);
  }

  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }
  getSixDigitNumber(): string {
    let number = "";
    this.inputFields.forEach((input) => {
      number += input.nativeElement.value;
    });
    return number;
  }

  checkLength() {
    this.valid_mobile = this.formatMobileNumber(this.mobile_data);
    this.mobileIsValid = false;
    if (
      !this.startsWithAny(this.mobile_keys, this.valid_mobile) &&
      this.valid_mobile.length >= this.keyLength
    ) {
      this.errorOccurred = true;
      this.successMobile = true;

      this.mobileIsInValid = true;

      this.translateService
        .get("invalid mobile message")
        .subscribe((translatedMessage: any) => {
          this.errorOccurredMessage = translatedMessage;
          this.errorMobileMessage = translatedMessage;
        });
    } else {
      this.errorOccurred = false;
      this.successMobile = false;
      this.mobileIsInValid = false;
      if (this.valid_mobile.length == this.mobileLength) {
        this.mobileIsValid = true;
      } else if (this.valid_mobile.length > this.mobileLength) {
        this.mobileIsInValid = true;
        this.mobileIsValid = false;
      } else {
        this.mobileIsValid = false;
      }
      this.errorOccurredMessage = "";
      this.errorMobileMessage = "";
    }
  }

  registerForm(): void {
    this.errorOccurred = false;
    this.errorOccurredMessage = "";
    if (this.isMobileLogin && this.settings?.register_client_on_mobile == 1) {
      if (!this.startsWithAny(this.mobile_keys, this.valid_mobile)) {
        this.errorOccurred = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("invalid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorOccurredMessage = translatedMessage;
          });
        return;
      } else if (this.valid_mobile.length < this.mobileLength) {
        this.errorOccurred = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("short mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorOccurredMessage = translatedMessage;
          });
        return;
      } else if (this.valid_mobile.length > this.mobileLength) {
        this.errorOccurred = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("long mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorOccurredMessage = translatedMessage;
          });
        return;
      } else if (!this.mobilePattern.test(this.valid_mobile)) {
        this.errorOccurred = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("valid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorOccurredMessage = translatedMessage;
          });
        return;
      }
    } else if (
      this.isEmailLogin &&
      parseInt(this.settings?.register_client_on_email) == 1
    ) {
      if (!this.Common.validateEmail(this.email_data)) {
        this.errorOccurred = true;
        this.translateService
          .get("valid mail")
          .subscribe((translatedMessage: any) => {
            this.errorOccurredMessage = translatedMessage;
          });
        return;
      }
    }
    if (!this.errorOccurred && this.isMobileLogin) {
      this.mobileIsValid = true;
      this.mobileIsInValid = false;
    } else if (!this.errorOccurred && this.isMobileLogin) {
      this.mobileIsValid = false;
      this.mobileIsInValid = false;
    }
    let body = {
      mode: this.isMobileLogin ? "mobile" : "email",
      mobile: this.isMobileLogin
        ? this.valid_mobile
        : this.Common.validateEmail(this.email_data)
        ? this.email_data
        : "",
      country_code: this.country_code,
      register_client_on_mobile_verification:
        this.settings.register_client_on_mobile_verification,
      register_client_on_email_verification:
        this.settings.register_client_on_email_verification,
    };
    this.user.cheackLoginByParams(body).subscribe({
      next: (res) => {
        console.log(res);
        this.verify_code = res.code;
        this.email_verify = body.mobile;
        if (res.message == "checkCode") {
          if (this.isEmailLogin) {
            this.translateService
              .get("CHECK_YOUR_EMAIL")
              .subscribe((translatedTitle: string) => {
                Swal.fire({
                  text: translatedTitle,
                  icon: "success",
                  customClass: {
                    title: "swal-title-center",
                  },
                  confirmButtonColor: getComputedStyle(
                    document.querySelector("app-root")
                  ).getPropertyValue("--theme-default"),
                }).then(() => {
                  this.mobile_data = "";
                  this.email_data = "";
                  this.password = "";
                  this.passwordFieldType = "password";
                  this.step1 = false;
                  this.step2 = true;
                  setTimeout(() => {
                    this.inputFields.toArray()[0].nativeElement.focus();
                  }, 0);
                  this.startTimer();
                });
              });
          } else if (this.isMobileLogin) {
            this.translateService
              .get("CHECK_YOUR_MOBILE")
              .subscribe((translatedTitle: string) => {
                Swal.fire({
                  text: translatedTitle,
                  icon: "success",
                  customClass: {
                    title: "swal-title-center",
                  },
                  confirmButtonColor: getComputedStyle(
                    document.querySelector("app-root")
                  ).getPropertyValue("--theme-default"),
                }).then(() => {
                  this.mobile_data = "";
                  this.email_data = "";
                  this.password = "";
                  this.passwordFieldType = "password";
                  this.step1 = false;
                  this.step2 = true;
                  setTimeout(() => {
                    this.inputFields.toArray()[0].nativeElement.focus();
                  }, 0);
                  this.startTimer();
                });
              });
          }
        } else if (res.message == "login") {
          this.step1 = false;
          this.step3 = true;
        } else if (res.message == "register") {
          this.password = "";
          this.passwordFieldType = "password";
          this.step1 = false;
          this.step4 = true;
          if (this.valid_mobile.length == this.mobileLength) {
            this.mobileIsValid = true;
          }
        } else if (res.message == "failed_mail" && this.isEmailLogin) {
          this.translateService
            .get("failed mail")
            .subscribe((translatedTitle: string) => {
              Swal.fire({
                text: translatedTitle,
                icon: "error",
                customClass: {
                  title: "swal-title-center",
                },
                confirmButtonColor: getComputedStyle(
                  document.querySelector("app-root")
                ).getPropertyValue("--theme-default"),
              });
            });
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  startsWithAny(array, number) {
    const numStr = number.toString();
    return array.some((element) => numStr.startsWith(element.toString()));
  }
  formatMobileNumber(mobileNumber) {
    if (mobileNumber.charAt(0) !== "0") {
      mobileNumber = "0" + mobileNumber;
    }
    return mobileNumber;
  }
  checkcode(): void {
    let number = this.getSixDigitNumber();
    console.log(number);
    if (number == this.verify_code) {
      this.step2 = false;
      this.step4 = true;
      if (this.valid_mobile.length == this.mobileLength) {
        this.mobileIsValid = true;
      }
      this.stopTimer();
    }
  }
  // createAccount() {
  //   this.errorOccurred = false;
  //   this.errorOccurredMessage = "";
  //   let body = {
  //     user_name: this.user_name,
  //     last_name: this.last_name,
  //     mobile: this.mobile_data,
  //     email: this.email_data,
  //     password: this.password,
  //   };
  //   console.log(body);
  //   this.user.userSignUp(body).subscribe((data) => {
  //     if (data.data) {
  //       localStorage.setItem("clientToken", data.data.authentication_code);
  //       localStorage.setItem("affiliatorToken", environment.affiliator_token);
  //       localStorage.setItem("currentUserFront", JSON.stringify(data.data));
  //       this.user.shareCartList().subscribe((data) => {
  //         console.log(JSON.stringify(data));
  //       });

  //       this.user.userLoggedIn(data.data.user_name);
  //       this.step4 = false;
  //       this.step5 = true;
  //       // if (
  //       //   localStorage.getItem("previousUrl") !== "" &&
  //       //   localStorage.getItem("previousUrl") != null &&
  //       //   localStorage.getItem("previousUrl") != "/pages/my-account"
  //       // ) {
  //       //   this.router.navigate([localStorage.getItem("previousUrl")]);
  //       // } else {
  //       //   this.router.navigate(["/pages/dashboard"]);
  //       // }
  //     }
  //   });
  // }

  createAccount() {
    this.successUser_name = false;
    this.successLast_name = false;
    this.successEmail = false;
    this.successPassword = false;
    this.successconfirmPassword = false;
    this.successMobile = false;
    this.mobileIsInValid = false;
    this.mobileIsValid = false;
    if (this.user_name == "" || this.user_name.length < 3) {
      this.successUser_name = true;
      this.translateService
        .get("first name message")
        .subscribe((translatedMessage: any) => {
          this.errorUserNameMessage = translatedMessage;
        });
      return;
    }
    if (this.last_name == "" || this.last_name.length < 3) {
      this.successLast_name = true;
      this.translateService
        .get("last name message")
        .subscribe((translatedMessage: any) => {
          this.errorLastNameMessage = translatedMessage;
        });
      return;
    }

    if (
      this.email_data != "" &&
      this.Common.validateEmail(this.email_data) == false
    ) {
      this.successEmail = true;
      this.translateService
        .get("valid mail")
        .subscribe((translatedMessage: any) => {
          this.errorSuccessEmailMessage = translatedMessage;
        });
      return;
    }

    if (!this.isMobileLogin) {
      if (!this.startsWithAny(this.mobile_keys, this.valid_mobile)) {
        this.successMobile = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("invalid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage = translatedMessage;
          });
        return;
      } else if (this.valid_mobile.length < this.mobileLength) {
        this.successMobile = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("short mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage = translatedMessage;
          });
        return;
      } else if (this.valid_mobile.length > this.mobileLength) {
        this.successMobile = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("long mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage = translatedMessage;
          });
        return;
      } else if (!this.mobilePattern.test(this.valid_mobile)) {
        this.successMobile = true;
        this.mobileIsInValid = true;
        this.translateService
          .get("valid mobile message")
          .subscribe((translatedMessage: any) => {
            this.errorMobileMessage = translatedMessage;
          });
        return;
      }
      if (!this.successMobile) {
        this.mobileIsValid = true;
        this.mobileIsInValid = false;
      }
    }
    // if (this.password == "") {
    //   this.successPassword = true;
    //   this.errorOccurred = true;
    //   this.translateService
    //     .get("Valid Password")
    //     .subscribe((translatedMessage: any) => {
    //       this.errorPasswordMessage = translatedMessage;
    //     });
    //   return;
    // }
    if (this.password == "" || this.password.length < 5) {
      this.successPassword = true;
      this.errorOccurred = true;
      this.translateService
        .get("short password")
        .subscribe((translatedMessage: any) => {
          this.errorPasswordMessage = translatedMessage;
        });
      return;
    }
    if (this.password != this.confirmPassword) {
      this.successconfirmPassword = true;
      this.translateService
        .get("Password Not Match")
        .subscribe((translatedMessage: any) => {
          this.errorconfirmPasswordMessage = translatedMessage;
        });
      return;
    }

    let body = {
      user_name: this.user_name,
      last_name: this.last_name,
      mobile: this.valid_mobile,
      email: this.email_data,
      password: this.password,
      country_code: this.country_code,
    };
    this.user.userSignUp(body).subscribe((data) => {
      if (data.data) {
        localStorage.setItem("clientToken", data.data.authentication_code);
        localStorage.setItem("affiliatorToken", environment.affiliator_token);
        localStorage.setItem("currentUserFront", JSON.stringify(data.data));

        this.user.shareCartList().subscribe((data) => {
          console.log(JSON.stringify(data));
        });

        this.user.userLoggedIn(data.data.user_name);
        this.step4 = false;
        this.step5 = true;
        this.stepSuccessReached.emit();
        this.step5Reached.emit();
      } else {
        this.errorOccurred = false;
        this.errorOccurredMessage = "";
        this.successUser_name = false;
        this.errorUserNameMessage = "";
        this.errorLastNameMessage = "";
        this.successEmail = false;
        this.errorSuccessEmailMessage = "";
        this.successPassword = false;
        this.errorPasswordMessage = "";
        this.successMobile = false;
        this.errorMobileMessage = "";
        if (data.errors) {
          this.errorOccurred = true;
          this.errorOccurredMessage = data.message;
        }
        if (data.errors["user_name"]) {
          this.successUser_name = true;
          this.errorUserNameMessage = data.errors["user_name"];
        }
        if (data.errors["last_name"]) {
          this.successUser_name = true;
          this.errorLastNameMessage = data.errors["last_name"];
        }
        if (data.errors["email"]) {
          this.successEmail = true;
          this.errorSuccessEmailMessage = data.errors["email"];
        }
        if (data.errors["password"]) {
          this.successPassword = true;
          this.errorPasswordMessage = data.errors["password"];
        }
        if (data.errors["mobile"]) {
          this.successMobile = true;
          this.mobileIsValid = false;
          this.mobileIsInValid = true;
          this.errorMobileMessage = data.errors["mobile"];
        }

        return;
      }
    });
  }

  doneSign(): void {
    this.errorOccurred = false;
    this.errorOccurredMessage = "";

    let body = {
      mobile: this.isMobileLogin ? this.valid_mobile : this.email_data,
      password: this.password,
    };

    this.user.userLogin(body).subscribe((data) => {
      if (data.data) {
        localStorage.setItem("clientToken", data.data.authentication_code);
        localStorage.setItem("affiliatorToken", environment.affiliator_token);
        localStorage.setItem("currentUserFront", JSON.stringify(data.data));
        this.user.shareCartList().subscribe((data) => {
          console.log(JSON.stringify(data));
        });

        this.user.userLoggedIn(data.data.user_name);
        this.step3 = false;
        this.step6 = true;
        this.stepSuccessReached.emit();
        this.step5Reached.emit();
      } else {
        this.errorOccurred = false;
        this.errorOccurredMessage = "";
        if (data.success == false) {
          this.errorOccurred = true;
          this.errorOccurredMessage = data.message;
        }
        return;
      }
    });
  }
  doneSignUP(): void {
    if (
      localStorage.getItem("previousUrl") !== "" &&
      localStorage.getItem("previousUrl") != null &&
      localStorage.getItem("previousUrl") != "/pages/my-account"
    ) {
      this.router.navigate([localStorage.getItem("previousUrl")]);
    } else {
      if (this.router.url == "/pages/login") {
        history.back();
        setTimeout(() => {
          location.reload(); 
        }, 100); 
      }
    }
  }
  goBackTO(num: number) {
    this.errorOccurred = false;
    switch (num) {
      case 1:
        this.step2 = false;
        this.step3 = false;
        this.step4 = false;
        this.step1 = true;
        break;
      case 2:
        this.step3 = false;
        this.step2 = true;
        setTimeout(() => {
          this.inputFields.toArray()[0].nativeElement.focus();
        }, 0);
        this.startTimer();
        break;
    }
  }
  loginclicked: boolean = false;
  goToLogin() {
    this.loginclicked = true;
  }

  close() {
    this.loginclicked = false;
  }

  passwordFieldType: string = "password";
  confirmPasswordFieldType: string = "password";

  togglePasswordVisibility(field: string): void {
    if (field === "password") {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    } else if (field === "confirmPassword") {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
    }
  }
}
