<div class="navbar">
  <button class="border-0 btn" (click)="leftMenuToggle()" aria-label="MenuToggle">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </button>
  <div id="mySidenav" class="sidenav" [style.direction]="Commen._lang_code == 'ar' ?'rtl' :'ltr'"
    [class.openSide]="navServices?.leftMenuToggle">
    <button class="sidebar-overlay" (click)="leftMenuToggle()" (mouseover)="onHover(false)"></button>
    <nav id="unset">
      <div class="d-flex align-items-center justify-content-between">
        <div (click)="leftMenuToggle()" class="">
          <div class="mt-3 text-left sidebar-back d-flex align-items-center justify-content-center"><i class="pr-2 fa fa-angle-left" aria-hidden="true"></i> <span>{{ 'Back' |
            translate }}</span>
          </div>
        </div>
        <div class="mx-3">
          <div class="d-flex">
              <ul class="d-flex">
                <li class="cursor d-flex" data-lng="es" 
              (click)="changeLanguage('ar')">
                  <img class="mx-2 flag img-fluid"  style="width:25px; height: 20px;"
                      src="https://www.countryflags.com/wp-content/uploads/saudi-arabia-flag-png-large.png" alt="arabic">
                  <span >{{"arabic" | translate}}
                  </span>
              </li>
              <li class="mx-2 cursor d-flex" data-lng="es" 
              (click)="changeLanguage('en')">
                  <img class="mx-2 flag img-fluid" style="width: 25px; height: 20px;"
                      src="https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png" alt="english">
                  <span >{{"english" | translate}} </span>
              </li>
              </ul>
            </div>
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(menuItem.children ? true : false)">
          <!-- Sub -->
          <div class="px-1 d-flex justify-content-between align-items-center">
            <button *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)" class="menuItem">
              {{menuItem.title}}
            </button>
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </div>
          <!-- Link -->
          <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
            {{menuItem.title}}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- 2nd Level Menu -->
          <ul [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''" [class.opensub1]="menuItem.active"
            *ngIf="menuItem.children">

            <!-- Simple Menu Start-->
            <ng-container *ngIf="!menuItem.megaMenu">
              <li *ngFor="let childrenItem of menuItem.children">
                <!-- Link -->
                <a class="has-submenu" [routerLink]="childrenItem.path" *ngIf="childrenItem.type === 'link'">
                  {{childrenItem.title}}
                </a>
                <!-- External Link -->
                <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'">
                  {{childrenItem.title}}
                </a>
                <!-- External Tab Link -->
                <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                  *ngIf="childrenItem.type === 'extTabLink'">
                  {{childrenItem.title}}
                </a>
                <!-- 3rd Level Menu -->
                <ul *ngIf="childrenItem.children">
                  <li *ngFor="let childrenSubItem of childrenItem.children">
                    <!-- Link -->
                    <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
                      {{childrenSubItem.title}}
                    </a>
                    <!-- External Link -->
                    <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
                      {{childrenSubItem.title}}
                    </a>
                    <!-- External Tab Link -->
                    <a href="{{ childrenSubItem.path }}" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
                      {{childrenSubItem.title}}
                    </a>
                    <!-- 4th Level Menu -->
                    <ul *ngIf="childrenSubItem.children">
                      <li *ngFor="let childrenSubSubItem of childrenSubItem.children">
                        <!-- Link -->
                        <a [routerLink]="childrenSubSubItem.path" *ngIf="childrenSubSubItem.type === 'link'">
                          {{childrenSubSubItem.title}}
                        </a>
                        <!-- External Link -->
                        <a href="{{ childrenSubSubItem.path }}" *ngIf="childrenSubSubItem.type === 'extLink'">
                          {{childrenSubSubItem.title}}
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ childrenSubSubItem.path }}" target="_blank"
                          *ngIf="childrenSubSubItem.type === 'extTabLink'">
                          {{childrenSubSubItem.title}}
                        </a>
                        <!-- 5th Level Menu -->
                        <ul *ngIf="childrenSubSubItem.children">
                          <li *ngFor="let childrenSubSubSubItem of childrenSubSubItem.children">
                            <!-- Link -->
                            <a [routerLink]="childrenSubSubSubItem.path" *ngIf="childrenSubSubSubItem.type === 'link'">
                              {{childrenSubSubSubItem.title}}
                            </a>
                            <!-- External Link -->
                            <a href="{{ childrenSubSubSubItem.path }}" *ngIf="childrenSubSubSubItem.type === 'extLink'">
                              {{childrenSubSubSubItem.title}}
                            </a>
                            <!-- External Tab Link -->
                            <a href="{{ childrenSubSubSubItem.path }}" target="_blank"
                              *ngIf="childrenSubSubSubItem.type === 'extTabLink'">
                              {{childrenSubSubSubItem.title}}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ng-container>
            <!-- Simple Menu End-->

            <!-- Mega Menu Start-->
            <ng-container *ngIf="menuItem.megaMenu">
              <li>
                <div class="m-0 row">
                  <div class="col-xl-4" *ngFor="let childrenItem of menuItem.children">
                    <div class="link-section">
                      <h5>{{childrenItem.title}}</h5>
                      <ul>
                        <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'link'">
                            {{childrenSubItem.title}}
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink'">
                            {{childrenSubItem.title}}
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink'">
                            {{childrenSubItem.title}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <a [routerLink]="['/home/one']" class="mega-menu-banner"><img src="" alt="" class="img-fluid"></a>
                  </div>
                </div>
              </li>
            </ng-container>
            <!-- Mega Menu End-->
          </ul>
        </li>

        <li>
          <a href="" *ngIf="userLogedIn" (click)="userLogOut()">
            {{'logout' | translate}}
          </a>
        </li>
      </ul>

    </nav>
  </div>
</div>

<!-- login -->
<section #login class="full-sidebar" *ngIf="loginclicked">
  <div class="shadow login-sidebar">
    <i class="ti-close login-icon" (click)="close()"></i>
    <app-fast-login></app-fast-login>
  </div>
</section>