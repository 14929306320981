import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { CategoriesService } from "./../../services/categories";
import { CommonService } from "../../../shared/services/common.service";
import { UserService } from "../../../shared/services/user.service";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[] = [];
  public categories: any[] = [];
  public children: any[] = [];
  public childrenchildren: any[] = [];

  constructor(
    private router: Router,
    public user: UserService,
    public navServices: NavService,
    private Commen: CommonService,
    private categoryService: CategoriesService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.navServices.items.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getMainCategories();
    }
  }

  getMainCategories() {
    this.categoryService?.getMainCategories().subscribe((data) => {
      //console.log"HERE IS THE DATA",data);

      if (data.data) {
        this.categories = data.data;
        console.log("cat", this.categories);

        this.children = [];

        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].haveSubService == false) {
            let child: Menu = {
              path:
                "/shop/category/" +
                this.categories[i].id +
                "/" +
                this.categories[i].ser_name,
              title: this.categories[i].ser_name,
              type: "link",
            };
            this.children.push(child);
          } else {
            this.childrenchildren = [];

            for (let ij = 0; ij < this.categories[i].subServices.length; ij++) {
              let childchild: Menu = {
                path:
                  "/shop/category/" +
                  this.categories[i].subServices[ij].id +
                  "/" +
                  this.categories[i].subServices[ij].ser_name
                    .split(" ")
                    .join("-"),
                title: this.categories[i].subServices[ij].ser_name,
                type: "link",
              };
              this.childrenchildren.push(childchild);
            }

            var child2: Menu = {
              title: this.categories[i].ser_name,
              type: "link",
              active: false,
              path:
                "/shop/category/" +
                this.categories[i].id +
                "/" +
                this.categories[i].ser_name.split(" ").join("-"),
              children: this.childrenchildren,
            };
            this.children.push(child2);
          }
        }
        var obj0: Menu = {
          path: "",
          title:
            localStorage.getItem("front-lang") == "en"
              ? "home"
              : "الصفحة الرئيسية",
          type: "link",
        };
        var obj1: Menu = {
          title:
            localStorage.getItem("front-lang") == "en"
              ? "Categories"
              : "الأقسام",
          type: "sub",
          active: false,
          children: this.children,
        };
        // page about us
        var obj3: Menu = {
          path: "/elements/trademarks",
          title:
            localStorage.getItem("front-lang") == "en"
              ? "Brands"
              : "العلامات التجارية",
          type: "link",
        };
        var obj2: Menu = {
          path:
            "/pages/page/38/" + localStorage.getItem("front-lang") == "en"
              ? "about-us"
              : "من-نحن",
          title:
            localStorage.getItem("front-lang") == "en" ? "about us" : "من نحن",
          type: "link",
        };

        this.menuItems = [];
        this.menuItems.push(obj0);
        this.menuItems.push(obj1);
        this.menuItems.push(obj3);
        this.menuItems.push(obj2);
        console.log("menu", this.menuItems);
      }
    });
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  ngAfterViewInit() {}
}
