import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { NavigationEnd, Router } from "@angular/router";
import { CategoriesService } from "./../../services/categories";
import { CommonService } from "../../../shared/services/common.service";
import { UserService } from "../../../shared/services/user.service";
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Meta } from "@angular/platform-browser";
import { SsrCookieService } from "ngx-cookie-service-ssr";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"],
})
export class LeftMenuComponent implements OnInit {
  public menuItems: Menu[];

  public categories: any[] = [];
  public children: any[] = [];
  public childrenchildren: any[] = [];
  public childs: any[] = [];
  notifictionNum = "0";
  userLogedIn: boolean = false;
  currentUser: any;
  user_img = "";
  existImg: boolean = false;
  user_full_name = "";
  usre_id: any;
  imageurl = "";
  public notificationsList: any[] = [];
  frontLang: string = localStorage.getItem("front-lang");

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    public user: UserService,
    public Commen: CommonService,
    public navServices: NavService,
    private categoryService: CategoriesService,
    private translate: TranslateService,
    private meta: Meta,
    private cookies: SsrCookieService

  ) {
    this.navServices.leftMenuItems.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

    router.events.subscribe((val) => {
      // see also
      if (this.navServices.leftMenuToggle) {
        this.navServices.leftMenuToggle = false;
      }
    });
  }

  ngOnInit(): void {
    this.getMainCategories();
    this.checkOuth();
    this.getUnReadNotificationNum();
    this.getNotificationsList();
  }
  userLogOut() {
    localStorage.removeItem("currentUserFront");
    localStorage.removeItem("clientToken");
    this.userLogedIn = false;
    this.currentUser = null;
    this.usre_id = "";

    this.user_img = "";
    this.user.logout();
    // this.router.navigate(["/pages/login"]);
    this.Commen.UpdatedSetting.next("logout");
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    });
    // this.goToLogin()
  }

  changeLanguage(code) {
    console.log(code);

    //console.log"language code is ", code);
    if (code == "ar") {
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
      localStorage.setItem("front-lang", "ar");
      this.cookies.delete("front-lang"); // Clear the existing cookie first
      this.cookies.set("front-lang", code, { path: "/" }); // Ensure cookie is set with the root path
      this.meta.updateTag({ property: "og:locale", content: code });
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
      localStorage.setItem("front-lang", "en");
      this.cookies.delete("front-lang"); // Clear the existing cookie first
      this.cookies.set("front-lang", code, { path: "/" }); // Ensure cookie is set with the root path
      this.meta.updateTag({ property: "og:locale", content: code });
    }

    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
    location.reload();
  }

  getUnReadNotificationNum() {
    this.notifictionNum = "0";
    this.Commen.getUnReadNotificationNum().subscribe((data) => {
      if (data.data) {
        this.notifictionNum = data.data;
      }
    });
  }
  getNotificationsList() {
    this.notificationsList = [];
    this.Commen.getNotificationsList().subscribe((data) => {
      if (data.data) {
        this.notificationsList = data.data;
      } else {
        this.notificationsList = [];
      }
    });
  }

  checkOuth() {
    if (this.user.getToken() !== null) {
      this.userLogedIn = true;
      this.currentUser = JSON.parse(this.user.getCurrentUser());
      this.usre_id = this.currentUser.id;
      this.user_img = this.currentUser.img;
      this.existImg =
        this.user_img != "" && this.user_img != null ? true : false;
      this.user_full_name = this.currentUser.user_name;
    } else {
      this.userLogedIn = false;
      this.currentUser = null;
      this.usre_id = "";
      this.existImg = false;

      this.user_img = "";
    }
  }
  getMainCategories() {
    this.categoryService.getMainCategories().subscribe((data) => {
      if (data.data) {
        this.categories = data.data;

        if (this.categories.length > 0) {
          this.children = [];
          for (let i = 0; i < this.categories.length; i++) {
            if (this.categories[i].haveSubService == false) {
              let child: Menu = {
                path:
                  "/shop/category/" +
                  this.categories[i].id +
                  "/" +
                  this.categories[i].ser_name,
                title: this.categories[i].ser_name,
                type: "link",
              };
              this.children.push(child);
            } else {
              this.childrenchildren = [];

              for (
                let ij = 0;
                ij < this.categories[i].subServices.length;
                ij++
              ) {
                let childchild: Menu = {
                  path:
                    "/shop/category/" +
                    this.categories[i].subServices[ij].id +
                    "/" +
                    this.categories[i].subServices[ij].ser_name,
                  title: this.categories[i].subServices[ij].ser_name,
                  type: "link",
                };
                this.childrenchildren.push(childchild);
              }
              var child2: Menu = {
                title: this.categories[i].ser_name,
                type: "link",
                active: false,
                path:
                  "/shop/category/" +
                  this.categories[i].id +
                  "/" +
                  this.categories[i].ser_name,
                children: this.childrenchildren,
              };
              this.children.push(child2);
            }
          }
          var obj0: Menu = {
            path: "",
            title:
              localStorage.getItem("front-lang") == "en"
                ? "home"
                : "الصفحة الرئيسية",
            type: "link",
          };
          var obj1: Menu = {
            title:
              localStorage.getItem("front-lang") == "en"
                ? "Categories"
                : "الأقسام",
            type: "sub",
            active: false,
            children: this.children,
          };
          var obj3: Menu = {
            path: "/elements/trademarks",
            title:
              localStorage.getItem("front-lang") == "en"
                ? "Brands"
                : "العلامات التجارية",
            type: "link",
          };
          // page about us
          var obj2: Menu = {
            path:
              "/pages/page/38/" + localStorage.getItem("front-lang") == "en"
                ? "about-us"
                : "من-نحن",
            title:
              localStorage.getItem("front-lang") == "en"
                ? "about us"
                : "من نحن",
            type: "link",
          };

          this.menuItems = [];
          this.menuItems.push(obj0);
          this.menuItems.push(obj1);
          this.menuItems.push(obj3);
          this.menuItems.push(obj2);
          if (this.userLogedIn == true) {
            this.childs = [
              {
                path: "/pages/dashboard",
                title:
                  localStorage.getItem("front-lang") == "en"
                    ? "Prfile"
                    : "الملف الشخصي",
                type: "link",
              },
              {
                path: "/pages/my-orders",
                title:
                  localStorage.getItem("front-lang") == "en"
                    ? "Orders"
                    : "الطلبات",
                type: "link",
              },
              {
                path: "/pages/my-coupones",
                title:
                  localStorage.getItem("front-lang") == "en"
                    ? "My Coupones"
                    : "كوبوناتي",
                type: "link",
              },
              {
                path: "/pages/profile",
                title:
                  localStorage.getItem("front-lang") == "en"
                    ? "Edit Profile"
                    : "تعديل الملف الشخصي",
                type: "link",
              },
            ];
            var obj4: Menu = {
              path: "/pages/dashboard",
              title:
                localStorage.getItem("front-lang") == "en"
                  ? "Prfile"
                  : "الملف الشخصي",
              type: "sub",
              active: false,
              children: this.childs,
            };
            this.menuItems.push(obj4);
            var obj5: Menu = {
              path: "/pages/dashboard",
              title:
                localStorage.getItem("front-lang") == "en"
                  ? "Notifications"
                  : "الاشعارات",
              type: "link",
            };
            this.menuItems.push(obj5);
          } else {
            var obj6: Menu = {
              path: "/pages/login",
              title:
                localStorage.getItem("front-lang") == "en"
                  ? "Login"
                  : "تسجيل دخول",
              type: "link",
            };
            this.menuItems.push(obj6);
            // var obj7: Menu = {
            //   "path": "/pages/register",
            //   "title": localStorage.getItem('front-lang') == 'en' ? "Create A Account" : "انشاء حساب",
            //   "type": "link"
            // }
            // this.menuItems.push(obj7);
          }
        }
      }
    });
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById("unset").classList.add("sidebar-unset");
    } else {
      document.getElementById("unset").classList.remove("sidebar-unset");
    }
  }

  loginclicked: boolean = false;

  goToLogin() {
    this.loginclicked = true;
  }
  close() {
    this.loginclicked = false;
  }
}
