import { Component, OnInit, Input } from "@angular/core";
import { CategoriesService } from "./../../services/categories";
import { CommonService } from "./../../services/common.service";
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { MasterApiService } from "../../services/master-api.service";
@Component({
  selector: "app-footer-one",
  templateUrl: "./footer-one.component.html",
  styleUrls: ["./footer-one.component.scss"],
})
export class FooterOneComponent implements OnInit {
  @Input() class: string = "footer-light"; // Default class
  logourl = "";
  @Input() themeLogo: string = this.logourl; // Default Logo
  @Input() newsletter: boolean = true; // Default True
  imageurl = "";
  public today: number = Date.now();
  public categories: any[] = [];
  brief;
  public pages: any[] = [];
  // public contanctInfo: any[] = [];
  public contanctInfo;
  paddingCondition: boolean = false;
  mobileNumber: string;
  allSupportAndBlog: any = [];
  ShowSupportBlock: number = 0;
  constructor(
    private categoryService: CategoriesService,
    public Commen: CommonService,
    private router: ActivatedRoute,
    private routes: Router,
    private MasterApiService: MasterApiService
  ) {
    this.imageurl = this.Commen._ImageUrl + "front_images/";
    this.paddingCondition = this.routes.url.includes("/product") ? true : false;

    this.routes.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes("/product")) {
          this.paddingCondition = true;
        } else {
          this.paddingCondition = false;
        }
      });
  }
  ngOnInit(): void {
    this.getMainCategories();
    this.getPages();
    this.getAllContanctInfo();
    this.getAllSupportAndBlogCategory();
  }

  getAllSupportAndBlogCategory() {
    this.Commen.getAllSupportAndBlogCategory().subscribe((data) => {
      if (data.success) {
        this.allSupportAndBlog = data.data;
        this.allSupportAndBlog?.forEach((element) => {
          if (element.show_in_footer == "1") {
            this.ShowSupportBlock += 1;
          }
        });
        console.log("count", this.ShowSupportBlock);
      }
    });
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  getMainCategories() {
    this.categoryService.getChildCategories(0).subscribe((data) => {
      if (data.data) {
        this.categories = data.data;
      }
    });
  }
  getPages() {
    this.Commen.getPages().subscribe((data) => {
      if (data.data) {
        this.pages = data.data;
      }
    });
  }
  getAllContanctInfo() {
    // this.Commen.getAllContanctInfo().subscribe((data) => {
    //   if (data.data) {
    //     this.contanctInfo = data.data;
    //     localStorage.setItem(
    //       "paymentInfo",
    //       JSON.stringify(this.Commen.getAllPaymentMethods(data.data))
    //     );
    //     // APPEND ZERO IN THE BEGINNING OF THE MOBILE NUMBER IF ITS NOT ALREADY THERE
    //     const mobileNumberFromApi = this.contanctInfo["mobile"];
    //     const firstNumberOfMobileNumber = Array.from(mobileNumberFromApi)[0];
    //     if (
    //       firstNumberOfMobileNumber == "0" ||
    //       firstNumberOfMobileNumber == "+"
    //     ) {
    //       this.mobileNumber = this.contanctInfo["mobile"];
    //     } else {
    //       this.mobileNumber = "0" + this.contanctInfo["mobile"];
    //     }
    //     this.contanctInfo["app_url_android"] = null;
    //     this.contanctInfo["app_url_ios"] = null;
    //     // console.log('this.mobileNumber ',this.mobileNumber);

    //     // this.brief = this.contanctInfo['brief'] ? this.contanctInfo['brief'] : 'No data';
    //     // this.fb=this.contanctInfo['facebook'] ? this.contanctInfo['facebook'] : 'No data';

    //     //console.logthis.contanctInfo);
    //     this.logourl = this.imageurl + this.contanctInfo["front_logo"];
    //     // console.log(" contanctInfo footer",this.contanctInfo);
    //   }
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.contanctInfo = dataContact;
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Commen.getAllPaymentMethods(dataContact))
        );
        // APPEND ZERO IN THE BEGINNING OF THE MOBILE NUMBER IF ITS NOT ALREADY THERE
        const mobileNumberFromApi = this.contanctInfo["mobile"];
        const firstNumberOfMobileNumber = Array.from(mobileNumberFromApi)[0];
        if (
          firstNumberOfMobileNumber == "0" ||
          firstNumberOfMobileNumber == "+"
        ) {
          this.mobileNumber = this.contanctInfo["mobile"];
        } else {
          this.mobileNumber = "0" + this.contanctInfo["mobile"];
        }
        // this.contanctInfo["app_url_android"] = null;
        // this.contanctInfo["app_url_ios"] = null;

        this.logourl = this.imageurl + this.contanctInfo["front_logo"];
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
}
