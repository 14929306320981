import {
  Component,
  OnInit,
  Input,
  HostListener,
  Injectable,
  PLATFORM_ID,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { UserService } from "../../../shared/services/user.service";
import { CommonService } from "../../../shared/services/common.service";
import { ProductService } from "../../../shared/services/product.service";
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Product } from "../../classes/product";

import randomString from "../../../randomstring";

import {
  Router,
  NavigationEnd,
  Event,
  NavigationStart,
  NavigationError,
  RoutesRecognized,
} from "@angular/router";
import { TokenService } from "../../services/token.service";
import { Meta } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { MasterApiService } from "../../services/master-api.service";
import { SsrCookieService } from "ngx-cookie-service-ssr";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent implements OnInit {
  frontLang: string = localStorage.getItem("front-lang");

  onResize(event?) {
    this.screenWidth = window.innerWidth;
    // console.log(this.screenWidth, this.screenWidth);
  }

  @Input() class: string;
  logourl = "";
  @Input() themeLogo: string = this.logourl; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  searchLoader: boolean = false; // Default
  productLoader: boolean = false; // Default
  showSearchBar = false;
  screenWidth: number;
  public products: Product[];
  public cart_products: Product[];
  public stick: boolean = false;
  userLogedIn: boolean = false;
  currentUser: any;
  user_img = "";
  existImg: boolean = false;
  user_full_name = "";
  usre_id: any;
  imageurl = "";
  urlimage = "";
  notifictionNum = "0";
  searchAutoComplete: boolean = false;
  searchAutoCompleteExist: boolean = false;
  totalCount: any;
  key: any;
  public query: string = "";
  flage: boolean = true;
  public searchAutoCompleteData: any[] = [];
  public searchAutoCompleteSuggesstions: any[] = [];
  public searchAutoCompleteBrands: any[] = [];
  // public contanctInfo: any[] = [];
  public contanctInfo;
  public notificationsList: any[] = [];
  mobileNumber: string;

  tokenID: string = "";

  innerWidth: any;
  subscription: Subscription;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public user: UserService,
    private router: Router,
    public commonService: CommonService,
    public productService: ProductService,
    private host: ElementRef<HTMLElement>,
    private tokenService: TokenService,
    private meta: Meta,
    private eRef: ElementRef,
    private MasterApiService: MasterApiService,
    private cookies: SsrCookieService
  ) {
    this.generatingDeviceID();
    // GET SCREEN WIDTH
    this.onResize();
    // RESET SHOWN PRODUCTS WHEN NAVIGATION CHANGES
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.searchAutoComplete = false;
      }
    });

    this.urlimage = this.commonService._ImageUrl + "products/thumbs/";

    this.imageurl = this.commonService._ImageUrl + "front_images/";
    this.urlimage = this.commonService._ImageUrl + "products/thumbs/";
    // console.log(this.urlimage);
    this.subscription = this.commonService.Updatedheaderone.subscribe((res) => {
      this.getCartItemsFromServer();
    });
  }

  ngOnInit(): void {
    this.user.getLoggedInName.subscribe((name) => this.changeName(name));
    this.checkOuth();
    this.getAllContanctInfo();
    this.getUnReadNotificationNum();
    this.getNotificationsList();
  }

  randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  generatingDeviceID() {
    let deviceGenerate = localStorage.getItem("generate-device");
    if (!deviceGenerate) {
      const generatDeviceId = this.randomString(
        40,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      localStorage.setItem("generate-device", generatDeviceId);
      let data = {
        device_token_id: generatDeviceId,
        type: "web",
        lang_code: localStorage.getItem("front-lang"),
      };
      this.tokenService.sendTokenApi(data).subscribe((success) => {
        if (success) {
          console.log(JSON.stringify(success));
          this.tokenID = success.data;
          localStorage.setItem("Device-Id", success.data);
        }
      });
    } else {
      let data = {
        device_token_id: deviceGenerate,
        type: "web",
        lang_code: localStorage.getItem("front-lang"),
      };
      this.tokenService.sendTokenApi(data).subscribe((success) => {
        if (success) {
          // console.log(JSON.stringify(success));
          this.tokenID = success.data;
          localStorage.setItem("Device-Id", success.data);
        }
      });
    }
  }

  private changeName(name: string): void {
    this.user_full_name = name;
    this.checkOuth();
  }

  toggleSearchBar() {
    this.showSearchBar = !this.showSearchBar;
  }

  getCartItemsFromServer() {
    // this.commonService.changeCart("");
    this.products = [];
    this.commonService.getCart().subscribe((data) => {
      // console.log("Line 113 Header one Component" + JSON.stringify(data));
      if (data.success) {
        // console.log(data);

        this.cart_products = data.data;
        this.cart_products.forEach((ele) => {
          ele.product_items.forEach((element) => {
            this.products.push(element);
          });
        });
        // console.log(this.products.length);
        // console.log(this.products);

        localStorage.setItem("cart_group_id", data.data[0].cart_group_id);
        // console.log("getCartItemsFromServer SettingsComponent",this.cart_products);
      } else {
        // console.log("no data in  getCartItemsFromServer SettingsComponent");
        localStorage.setItem("cart_group_id", "0");
      }
    });
  }
  removeBrackets(str: string): string {
    return str.replace(/[/(\)%]/g, "");
  }
  productDetails(searchItem) {
    this.searchAutoComplete = false;
    this.router.navigate([
      "/shop/product/",
      searchItem.id,
      searchItem.meta_url
        ? searchItem.meta_url?.split(" ").join("-")
        : this.removeBrackets(searchItem.name).split(" ").join("-"),
    ]);
  }
  handleImageError(e) {
    e.src = "assets/images/product/placeholder2.jpg";
  }
  ProductReports() {
    this.productService
      .addSearchProductReports(this.key, this.totalCount)
      .subscribe((res) => {
        // console.log(res);
      });
  }

  getProductsAutoComplete(mode) {
    this.openDropdown();
    this.showDropDown = false;
    this.searchAutoComplete = false;
    this.searchAutoCompleteExist = false;
    // this.searchAutoCompleteBrands = [];
    // this.searchAutoCompleteSuggesstions = [];
    // this.searchAutoCompleteData = [];

    if (
      (this.query !== "" && mode === "brands") ||
      mode === "suggesstions_products_search_keys"
    ) {
      this.searchLoader = true;
      this.productService
        .getProductsAutoComplete(this.query, 0, 10, mode)
        .subscribe((data) => {
          this.key = this.query;
          this.searchAutoComplete = true;
          this.searchLoader = false;
          if (data) {
            if (mode == "brands") {
              this.searchAutoCompleteBrands = data.brands;
            }

            if (mode == "suggesstions_products_search_keys") {
              this.searchAutoCompleteSuggesstions =
                data.suggesstions_products_search_keys;
            }
          } else {
            this.totalCount = 0;
            // this.searchAutoCompleteData = [];
            // // Code Added
            // this.searchAutoCompleteBrands = []
            // this.searchAutoCompleteSuggesstions = []
          }
        });
    }
    if (mode === "products" && this.query.length >= 3) {
      this.productLoader = true;
      this.productService
        .getProductsAutoComplete(this.query, 0, 10, mode)
        .subscribe((data) => {
          this.key = this.query;
          this.searchAutoComplete = true;
          this.productLoader = false;
          if (data) {
            this.totalCount = data.total_count;
            this.searchAutoCompleteData = data.data;
            console.log(this.searchAutoCompleteData);

            this.productLoader = false;
            if (this.searchAutoCompleteData.length > 0) {
              for (
                let index = 0;
                index < this.searchAutoCompleteData.length;
                index++
              ) {
                if (
                  this.searchAutoCompleteData[index].store_amounts > 0 &&
                  this.searchAutoCompleteData[index].price > 0
                ) {
                  this.searchAutoCompleteExist = true;
                }
              }
            }
          } else {
            this.totalCount = 0;
            // this.searchAutoCompleteData = [];
            // // Code Added
            // this.searchAutoCompleteBrands = []
            // this.searchAutoCompleteSuggesstions = []
          }
        });
    } else {
      this.searchAutoComplete = false;
      // this.searchAutoCompleteData = [];
      this.searchAutoCompleteExist = false;
    }
  }

  goToSearch() {
    if (this.query != "") {
      this.router.navigate([
        "/pages/search/" + "web/" + this.removeBrackets(this.query),
      ]);
      this.query = "";
    }
  }
  goToSearchSuggested(keyword: any) {
    console.log(this.query);

    if (this.query != "") {
      this.router.navigate([
        "/pages/suggesstion/" + keyword,
        // this.removeBrackets(keyword).split(" ").join("-"),
      ]);
    }
  }
  clearSearch(inputSearch = "") {
    this.query = "";
  }
  changeLanguage(code) {
    console.log(code);

    //console.log"language code is ", code);
    if (code == "ar") {
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
      localStorage.setItem("front-lang", "ar");
      this.cookies.delete("front-lang"); // Clear the existing cookie first
      this.cookies.set("front-lang", code, { path: "/" }); // Ensure cookie is set with the root path
      this.meta.updateTag({ property: "og:locale", content: code });
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
      localStorage.setItem("front-lang", "en");
      this.cookies.delete("front-lang"); // Clear the existing cookie first
      this.cookies.set("front-lang", code, { path: "/" }); // Ensure cookie is set with the root path
      this.meta.updateTag({ property: "og:locale", content: code });
    }

    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
    location.reload();
  }

  userLogOut() {
    localStorage.removeItem("currentUserFront");
    localStorage.removeItem("clientToken");
    this.userLogedIn = false;
    this.currentUser = null;
    this.usre_id = "";

    this.user_img = "";
    this.user.logout();
    this.commonService.UpdatedSetting.next("logout");
    this.router.navigate(["/"]).then(() => {
      window.location.reload();
    });
    // this.goToLogin()
  }

  checkOuth() {
    if (this.user.getToken() !== null) {
      this.userLogedIn = true;
      this.currentUser = JSON.parse(this.user.getCurrentUser());
      this.usre_id = this.currentUser.id;
      this.user_img = this.currentUser.img;
      this.existImg =
        this.user_img != "" && this.user_img != null ? true : false;
      this.user_full_name = this.currentUser.user_name;
      // console.log("curent user",this.currentUser);
    } else {
      this.userLogedIn = false;
      this.currentUser = null;
      this.usre_id = "";
      this.existImg = false;

      this.user_img = "";
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  getAllContanctInfo() {
    // this.commonService.getAllContanctInfo().subscribe((data) => {
    //   // console.log('getAllContanctInfo', data.data);

    //   if (data.data) {
    //     this.contanctInfo = data.data;
    //     localStorage.setItem(
    //       "paymentInfo",
    //       JSON.stringify(this.commonService.getAllPaymentMethods(data.data))
    //     );
    //     localStorage.setItem("show_price", this.contanctInfo["show_price"]);
    //     this.logourl = this.imageurl + this.contanctInfo["front_logo"];
    //     this.host.nativeElement.style.setProperty(
    //       "--theme-default",
    //       data.data["front_color"] ? data.data["front_color"] : "#cc0000"
    //     );
    //     this.host.nativeElement.style.setProperty(
    //       "--theme-default2",
    //       data.data["front_color_2"]
    //         ? data.data["front_color_2"]
    //         : "rgba(254, 212, 48, 1)"
    //     );

    //     // APPEND ZERO IN THE BEGINNING OF THE MOBILE NUMBER IF ITS NOT ALREADY THERE
    //     const mobileNumberFromApi = this.contanctInfo["mobile"];
    //     const firstNumberOfMobileNumber = Array.from(mobileNumberFromApi)[0];
    //     if (
    //       firstNumberOfMobileNumber == "0" ||
    //       firstNumberOfMobileNumber == "+"
    //     ) {
    //       this.mobileNumber = this.contanctInfo["mobile"];
    //     } else {
    //       this.mobileNumber = "0" + this.contanctInfo["mobile"];
    //     }
    //   }
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.contanctInfo = dataContact;
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.commonService.getAllPaymentMethods(dataContact))
        );
        localStorage.setItem("show_price", this.contanctInfo["show_price"]);
        this.logourl = this.imageurl + this.contanctInfo["front_logo"];
        this.host.nativeElement.style.setProperty(
          "--theme-default",
          dataContact["front_color"] ? dataContact["front_color"] : "#cc0000"
        );
        this.host.nativeElement.style.setProperty(
          "--theme-default2",
          dataContact["front_color_2"]
            ? dataContact["front_color_2"]
            : "rgba(254, 212, 48, 1)"
        );

        // APPEND ZERO IN THE BEGINNING OF THE MOBILE NUMBER IF ITS NOT ALREADY THERE
        const mobileNumberFromApi = this.contanctInfo["mobile"];
        if (mobileNumberFromApi) {
          const firstNumberOfMobileNumber = Array.from(mobileNumberFromApi)[0];
          if (
            firstNumberOfMobileNumber == "0" ||
            firstNumberOfMobileNumber == "+"
          ) {
            this.mobileNumber = this.contanctInfo["mobile"];
          } else {
            this.mobileNumber = "0" + this.contanctInfo["mobile"];
          }
        } else {
          console.error("Mobile number is undefined or null");
        }
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  getUnReadNotificationNum() {
    this.notifictionNum = "0";
    this.commonService.getUnReadNotificationNum().subscribe((data) => {
      if (data.data) {
        this.notifictionNum = data.data;
      }
    });
  }
  getNotificationsList() {
    this.notificationsList = [];

    this.commonService.getNotificationsList().subscribe((data) => {
      if (data.data) {
        this.notificationsList = data.data;
        // console.log('notifications list ', this.notificationsList);
      } else {
        this.notificationsList = [];
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showDropDown: boolean = false;
  // closeDropdown() {
  //   this.showDropDown = true;
  // }

  // @HostListener("window:click", ["$event"])
  // clickout(event) {
  //   if (!this.eRef.nativeElement.contains(event.target)) {
  //     console.log(this.eRef.nativeElement);
  //     console.log(event.target);
  //     this.showDropDown = true;
  //   }
  // }

  // closeDropdown(event) {
  //   if (!this.eRef.nativeElement.contains(event.target)) {
  //     this.showDropDown = false;
  //   }
  // }
  @ViewChild("dropdown") dropdown: ElementRef;
  @ViewChild("searchKeyE") searchKeyE: ElementRef;

  openDropdown() {
    if (this.query) {
      this.dropdown.nativeElement.style.display = "block";
    }
  }

  closeDropdown() {
    if (this.dropdown?.nativeElement.style.display == "block") {
      this.dropdown.nativeElement.style.display = "none";
    }
  }

  loginclicked: boolean = false;

  // goToLogin() {
  //   this.loginclicked = true;
  // }
  // close() {
  //   this.loginclicked = false;
  // }

  step5Reached: boolean = false;
  stepSuccessReached: boolean = false;
  timeoutId: any;

  @ViewChild("login") loginSection;
  @ViewChild("loginFast") loginFast;

  goToLogin() {
    this.loginclicked = true;
  }

  close() {
    this.loginclicked = false;
    this.step5Reached = false;
    this.clearTimeout();
    if (this.stepSuccessReached) {
      window.location.reload();
    }
  }

  onStep5Reached() {
    this.step5Reached = true;
    this.startTimeout();
  }
  onstepSuccessReached() {
    this.stepSuccessReached = true;
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.close();
    }, 2000);
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  // @HostListener("document:click", ["$event"])
  // clickInsideDropdown(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   if (
  //     target &&
  //     !this.searchKeyE?.nativeElement.contains(target) &&
  //     !this.dropdown?.nativeElement.contains(target)
  //   ) {
  //     this.closeDropdown();
  //   }
  // }
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      target &&
      !this.searchKeyE?.nativeElement.contains(target) &&
      !this.dropdown?.nativeElement.contains(target)
    ) {
      this.closeDropdown();
    }
    if (!this.loginFast || !this.loginSection) {
      return;
    }
    const clickedInside = this.loginFast.nativeElement.contains(event.target);
    const clickedButton = this.loginSection.nativeElement.contains(
      event.target
    );

    if (clickedInside && this.loginclicked) {
      if (this.step5Reached) {
        this.close();
      }
    } else if (!clickedInside && clickedButton && this.loginclicked) {
      this.close();
    }
  }
}
