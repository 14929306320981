<app-breadcrumb [title]="page_name" [breadcrumb]="page_name"></app-breadcrumb>
<!--section start-->
<section class="wishlist-section section-b-space">
    <div class="container">
        <div class="col-md-12 empty-cart-cls text-center" *ngIf='!products.length'>
            <img src="assets/images/empty-wishlist.png" alt="Empty cart" title="Emprt cart">
            <h3 class="mt-4"><b>{{'Wishlist is Empty' | translate}}</b></h3>
            <h4></h4>
        </div>
        <div class="row" *ngIf='products.length'>


            <div class="col-sm-12 table-layout">
                <table class="table cart-table table-responsive-xs">
                    <thead>
                        <tr class="table-head">
                            <th scope="col" width="140px">{{'image' | translate}}</th>
                            <th class="name" scope="col">{{'Product Name' | translate}}</th>
                            <th scope="col" width="140px">{{'Price' | translate}}</th>
                            <!-- <th scope="col">{{'browse_date' | translate}}</th> -->
                            <!-- <th scope="col">availability</th> -->
                            <th scope="col" width="140px">{{'ACTION' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let product of products ; let index = index">
                        <tr>
                            <td>
                                <a [routerLink]="['/shop/product/', product.id , !product.meta_url ? removeBrackets(product.name)?.split(' ').join('-') :product.meta_url?.split(' ').join('-')]" *ngIf="product.images.length > 0">

                                    <img [src]="urlimage+product.images[0].name" [alt]="product.name"
                                        *ngIf="product.images[0]['type'] !== 'video' && product.images.length !== 0" (error)="handleImageError($event.target)">

                                    <img src="assets/images/product/placeholder.jpg" *ngIf="!product.image && product.images.length == 0">

                                    <img src="assets/images/video.png" [alt]="product.name"
                                        *ngIf="product.images[0]['type'] === 'video'">

                                </a>
                            </td>

                            <td style="text-align: start;">
                                <a [routerLink]="['/shop/product/', product.id,  !product.meta_url ? removeBrackets(product.name ? product.name : product.product_name)?.split(' ').join('-') :product.meta_url?.split(' ').join('-')]">{{ product.name ? product.name : product.product_name | titlecase }}
                                </a>
                                <div class="mobile-cart-content row">
                                    <!-- <div class="col-xs-3">
                                <p>{{ product.store_amounts > 0 ? 'in stock' : 'out of stock' }}
                                </p>
                            </div> -->
                                    <div class="col-xs-3 mb-2">
                                        <p>{{ product.history_date_added_since }}
                                        </p>
                                    </div>
                                    <div class="col-xs-3 mb-2">
                                        <h2 class="td-color">
                                            {{ (product.price | discount:product) * productService?.Currency.price |
                                            currency:productService?.Currency.currency:'symbol' }}
                                        </h2>
                                    </div>
                                    <div class="col-xs-3 mb-2">
                                        <!-- <h2 class="td-color">
                                            <a href="javascript:void(0)" class="icon mr-1 mb-1">
                                                <i class="ti-close"></i>
                                            </a>
                                            <a [routerLink]="['/shop/cart']" (click)="addToCart(product)" class="cart">
                                                <i class="ti-shopping-cart"></i>
                                            </a>
                                        </h2> -->
                                    </div>
                                </div>
                            </td>

                            <td>
                                <h2>
                                    {{ (product.price | discount:product) * productService?.Currency.price }}
                                    {{productService?.Currency.name}}
                                </h2>
                            </td>


                            <td class="actions-cell" *ngIf="product.active != 2">
                                <a href="javascript:void(0)" (click)="addToCart(product)" class="cart">
                                    <i class="fa fa-cart-plus" aria-hidden="true"></i>

                                </a>
                                &nbsp;/&nbsp;
                                <a href="javascript:void(0)" (click)="removeItem(product)" class="icon mr-3">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </a>
                            </td>
                            <td *ngIf="product.active == 2">
                                <span class="text-danger">
                                    {{ "Inventory is in progress" | translate }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-sm-12">   
                <div class="mobile-wishlist-layout">
                    <app-browse-log-item class="single-log-item" (listUpdated)="removeItem(product)"
                        *ngFor="let product of products ; let index = index" [product]="product"></app-browse-log-item>
                </div>
            </div>


        </div>
        <div class="row wishlist-buttons" *ngIf='products.length'>
            <div class="col-12">
                <a [routerLink]="['/']" class="btn btn-solid">{{'continue shopping' | translate}}</a>
                <a [routerLink]="['/shop/checkout']" class="btn btn-solid">{{'Check out' | translate}}</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->