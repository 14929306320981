export const environment = {
  firebase: {
    projectId: 'turbo-391bc',
    appId: '1:834753379746:web:20e3708cf74500636306fb',
    storageBucket: 'turbo-391bc.appspot.com',
    apiKey: 'AIzaSyD35pPHfgkR10EVL8rLaO1u42pvvpdb1zw',
    authDomain: 'turbo-391bc.firebaseapp.com',
    messagingSenderId: '834753379746',
    measurementId: 'G-CMQTG7RLL5',
    vapidKey: "BLukYfOKH3ZFEicGryCFVsBmg-xIWn6fmTC0HT9VSyPzaUH-SgtuvwkNc8rGR2MZeuBcnyaRPZmrPe5_A0eU_YU"
  },
  production: true,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  affiliator_token: '$2y$10$oWVX50EVQ6JcD3wYmT2VrOaJU4vyB7TkO55.nNzfDTW7jh30L3RgO',
};
