import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MasterApiService } from '../../services/master-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-services-list',
  templateUrl: './customer-services-list.component.html',
  styleUrls: ['./customer-services-list.component.scss']
})
export class CustomerServicesListComponent implements OnInit {
  @ViewChild('list') list: ElementRef;
  
  
  settings: any;
  labelWhatAppUrl: string;
  customer_service: any;
  labelBaseUrl: string;
  items: any;
  menuOpen = false;
  countryInfo: any;
  defaultImages: any[] = [];


  constructor(
    private Common:CommonService,
    private MasterApiService:MasterApiService,
    private router:Router,
  ) { }
  
  ngOnInit(): void {
    this.getAllContanctInfo();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const windowWidth = window.innerWidth;
    if (this.list) {
      if (number > 600) { 
        if (windowWidth < 577 && this.list) { // Example breakpoint for mobile
          this.list.nativeElement.style.bottom = '120px'; // Adjust value for mobile
        } else {
          this.list.nativeElement.style.bottom = '100px'; // Value for larger screens
        }
      } else {
        if (windowWidth > 577 && this.list) {
          this.list.nativeElement.style.bottom = '30px'; // Adjust value for mobile
        } else {
          this.list.nativeElement.style.bottom = '70px'; // Value for larger screens
        }
      }
    }
  }

  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.settings = dataContact;
        this.customer_service = this.settings['customer_service'];
        this.countryInfo = this.settings['country_info']
        this.labelBaseUrl = this.Common._ImageUrl + "customer-service/";
        this.items = this.customer_service?.['home_page']
        console.log( "list" , this.items);
        this.items.forEach((ele) => {
          switch (ele?.type) {
            case "whatsApp":
              this.defaultImages.push("assets/images/social/DWhatsapp.jpg")
                break;
            case 'facebook':
              this.defaultImages.push("assets/images/social/DFacebook.png")
              break;
            case "call":
            this.defaultImages.push("assets/images/social/DCall.png")
            break;
            case "messenger":
              this.defaultImages.push("assets/images/social/DMessenger.jpg")
              break;
            case "twitter":
              this.defaultImages.push("assets/images/social/DX.png")
              break;
            case "instagram":
              this.defaultImages.push("assets/images/social/DInstgram.jpg")
              break;
            case "snapchat":
              this.defaultImages.push("assets/images/social/DSnapchat.png")
              break;
            case "tikTok":
              this.defaultImages.push("assets/images/social/DTiktok.png")
              break;
        }

        });
        
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  action(item)
  {
    const windowWidth = window.innerWidth;
    let url = item?.value; 
    if (item?.type == 'call') {
      window.open(`tel:${url}`, '_self');
    }
    else if(item?.type == 'whatsApp')
    {
      window.open('https://api.whatsapp.com/send?phone=' + url, '_blank');
    }
    else if(item?.type == 'messenger')
    {
      window.open('https://www.messenger.com/t/' + url, '_blank');
    }
    else if(item?.type == 'instagram')
    {
      let instaUl = ''
      windowWidth > 600 ? instaUl = 'https://www.instagram.com/m/' : instaUl = 'https://www.instagram.com/'
      window.open( instaUl + url, '_blank');
    }
    else if(item?.type == 'tikTok')
    {
      window.open('https://www.tiktok.com/@' + url, '_blank');
    }
    else if(item?.type == 'snapchat')
    {
      window.open('https://www.snapchat.com/add/' + url, '_blank');
    }
    else if(item?.type == 'twitter')
    {
      window.open('https://x.com/messages/' + url, '_blank');
    }
    else if(item?.type == 'facebook')
    {
      window.open('https://www.facebook.com/share/' + url, '_blank');
    }
    else{
      window.open(url, '_blank');
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }
}
