import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: "app-inline-style",
  templateUrl: "./inline-style.component.html",
  styleUrls: ["./inline-style.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InlineStyleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
