import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { MasterApiService } from "../../services/master-api.service";

@Component({
  selector: "app-download-app-banner",
  templateUrl: "./download-app-banner.component.html",
  styleUrls: ["./download-app-banner.component.scss"],
})
export class DownloadAppBannerComponent implements OnInit {
  downloadAppBar: boolean = true;
  downloadLink: string;
  innerWidth: any;
  websiteFavIcon = "";
  settingsData: any;

  constructor(
    public commonService: CommonService,
    private MasterApiService: MasterApiService
  ) {}
  contactData: any;
  ngOnInit(): void {
    // console.log('websiteFavIcon ', this.websiteFavIcon);
    // this.commonService.getAllContanctInfo().subscribe((data) => {
    //   this.websiteFavIcon =
    //     this.commonService._ImageUrl +
    //     "front_images/" +
    //     data.data["front_fav_icon"];
    //   this.contactData = data.data;
    //   localStorage.setItem(
    //     "paymentInfo",
    //     JSON.stringify(this.commonService.getAllPaymentMethods(data.data))
    //   );
    //   this.getMobileOperatingSystem();
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.websiteFavIcon =
          this.commonService._ImageUrl +
          "front_images/" +
          dataContact["front_fav_icon"];
        this.contactData = dataContact;
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.commonService.getAllPaymentMethods(dataContact))
        );
        this.getMobileOperatingSystem();
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  closeDownloadBanner() {
    this.downloadAppBar = false;
  }

  getMobileOperatingSystem() {
    this.innerWidth = window.innerWidth;
    // console.log("WIDTH OF SCREEN IS = ", this.innerWidth);

    var userAgent =
      navigator.userAgent || navigator.vendor || (<any>window).opera;

    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {

    //     return "Windows Phone";
    // }

    // if (/android/i.test(userAgent)) {
    //   downloadLink =
    //     "https://play.google.com/store/apps/details?id=app.zahra.egypt";
    //     return downloadLink;
    // }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !(<any>window).MSStream) {
      this.downloadLink = this.contactData?.app_url_ios;
      return this.downloadLink;
    } else {
      this.downloadLink = this.contactData?.app_url_android;
      return this.downloadLink;
    }
    // return "Android";
  }
}
