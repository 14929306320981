import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { CategoriesService } from './../../services/categories';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;
  public categories: any[] = [];
  constructor(public productService: ProductService, private categoryService: CategoriesService , private _common:CommonService) {
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.getMainCategories();
    console.log('cc');
    
  }
  getMainCategories() {

    this.categoryService.getChildCategories(0).subscribe((data) => {
      if (data.data) {
        this.categories = data.data;        
        
      }
    });
  }

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.type))]
    return category
  }

}
