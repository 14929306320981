import { Component, OnInit } from "@angular/core";
import { CommonService } from "./../../../../shared/services/common.service";
import { MasterApiService } from "src/app/shared/services/master-api.service";
@Component({
  selector: "app-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.scss"],
})
export class SocialComponent implements OnInit {
  // public contanctInfo: any[] = [];
  public contanctInfo;
  constructor(
    private Commen: CommonService,
    private MasterApiService: MasterApiService
  ) {}

  ngOnInit(): void {
    this.getAllContanctInfo();
  }
  getAllContanctInfo() {
    // this.Commen.getAllContanctInfo().subscribe((data) => {
    //   if (data.data) {
    //     this.contanctInfo = data.data;
    //     localStorage.setItem(
    //       "paymentInfo",
    //       JSON.stringify(this.Commen.getAllPaymentMethods(data.data))
    //     );
    //     // console.log(" contanctInfo footer",this.contanctInfo);
    //   }
    // });

    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }

        this.contanctInfo = dataContact;
        localStorage.setItem(
          "paymentInfo",
          JSON.stringify(this.Commen.getAllPaymentMethods(dataContact))
        );
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }
}
