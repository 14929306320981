import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import SwiperCore, { FreeMode, Navigation, Pagination, SwiperOptions, Thumbs } from "swiper";
import { ProductService } from '../../../services/product.service';
import { CommonService } from '../../../services/common.service';
// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs, Pagination]);

@Component({
  selector: 'app-related-products-slider',
  templateUrl: './related-products-slider.component.html',
  styleUrls: ['./related-products-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedProductsSliderComponent implements OnInit {
  @Input() productsList: any[];

  thumbsSwiper: any;

  relatedProductsSliderConfig: SwiperOptions = {
    
    slidesPerView: 'auto',
    spaceBetween: 0,
    navigation: true,
    // pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  constructor(
    public productService: ProductService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   console.log(this.productsList);
    // }, 10000);
  }
  test(w=0,y=0)
  {
    console.log('test',w,y);
    
  }
}
