<div class="speed-dial-container" (click)="toggleMenu()" #list *ngIf="items.length > 0">
    <button class="fab" >
        <img *ngIf="!menuOpen" src="assets/images/list-icon.png" class="img-fluid" alt="list">
        <span *ngIf="menuOpen" class="text-white">
            X
        </span>
    </button>
    <div class="speed-dial-menu" [class.open]="menuOpen">
        <button
        *ngFor="let item of items; let index = index" 
        class="speed-dial-item" (click)="action(item)"
        >
            <img 
            [src]="labelBaseUrl + item.img" 
            [lazyLoad]="labelBaseUrl + item.img"
            [defaultImage]="defaultImages[index]" class="img-fluid" alt="social-icon"
            >
        </button>
    </div>
</div>
