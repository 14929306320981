<div class="collection-collapse-block" [class.open]="collapse" *ngIf="filterbycolor.length">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">colors</h3>
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter color-filter">
        <div class="custom-control custom-checkbox collection-filter-checkbox"
          *ngFor="let color of filterbycolor">
          <input 
            type="checkbox" 
            [value]="color"
            [checked]="checked(color)"
            [id]="color"
            (change)="appliedFilter($event)"
            class="custom-control-input" />
          <span [class]="color" [ngStyle]="{'background-color': color}"></span>
          <label class="custom-control-label" for="{{ color }}">{{ color }}</label>
        </div>
      </div>
    </div>
</div>